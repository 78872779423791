import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { getLabel, renderContent } from '../../utils/helpers'
import { PersonFieldPaths as paths, PersonConditions } from '../../utils/QuestionnaireStateManager'
import Chooser from '../../widgets/Chooser'
import ConditionalSection from '../../widgets/ConditionalSection'

export const BiopsyResults = ({ ...props }) => {
  return (
    <Chooser
      orderSchema={['normal', 'lcis', 'atypicalHyperplasia', 'hyperplasia', 'unknown']}
      patient="patient"
      {...props}
    />
  )
}

const BreastBiopsy = ({ id, patient, config, isLCIS }) => {
  const {
    questionnaire: { persons },
    localization,
  } = useSelector(({ questionnaire, localization }) => ({ questionnaire, localization }))
  const {
    removePersonCancerRiskProperty,
    setPersonPreviousBreastBiopsies,
    setPersonBreastBiopsyResult,
    setPersonConditions,
    removePersonConditions,
  } = useActions()

  const {
    cancerRiskData: { previousBreastBiopsies = '', breastBiopsyResult = '' } = {},
    sex,
    conditions = [],
  } = persons[id]
  const LCIS = conditions.find(({ id }) => id === PersonConditions.LCIS)?.isPresent === 'Y'

  return sex === 'F' ? (
    <ConditionalSection
      label={getLabel(localization, paths.BREAST_BIOPSY_LABEL, patient)}
      conditionState={previousBreastBiopsies}
      onConditionStateChange={(value) => {
        setPersonPreviousBreastBiopsies({ id, value })
      }}
      cleanUpChildQuestions={() => {
        if (LCIS) {
          removePersonConditions({ id, conditionId: PersonConditions.LCIS })
        }
        if (breastBiopsyResult) {
          removePersonCancerRiskProperty({ id, type: 'breastBiopsyResult' })
        }
      }}
      showWhenCondition="Y"
      orderSchema={['Y', 'N', 'U']}
    >
      {renderContent({
        renderMap: {
          breastBiopsyResult: (
            <BiopsyResults
              key="br"
              label={getLabel(localization, paths.BREAST_BIOPSY_RESULT_LABEL, patient)}
              onChange={(value) => {
                // prevent actions when clicking multiple times
                if (value === breastBiopsyResult) return

                if (value === 'lcis') {
                  setPersonConditions({ id, conditionId: PersonConditions.LCIS })
                } else if (LCIS) {
                  removePersonConditions({ id, conditionId: PersonConditions.LCIS })
                }
                setPersonBreastBiopsyResult({ id, value })
              }}
              selectedValue={breastBiopsyResult}
              path={paths.BREAST_BIOPSY_RESULT_OPTIONS}
            />
          ),
        },
        config,
      })}
    </ConditionalSection>
  ) : null
}

export default BreastBiopsy
