import AddIcon from '@mui/icons-material/Add'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import LabelIcon from '@mui/icons-material/Label'
import {
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material'
import _ from 'lodash'
import React, { useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import uuidv4 from 'uuid/v4'
import { useActions } from '../hooks/useActions.jsx'
import * as CancerTypes from '../utils/CancerTypes.js'
import Context from '../utils/context/Context.jsx'
import DialogWindow from './DialogWindow'
import FormLabel from './FormLabel.jsx'
import SearchSelect from './SearchSelect.jsx'
import SpecificCancerQuestions from './SpecificCancerQuestions.jsx'

const filter = createFilterOptions()

// TODO: this component needs refactoring to reduce complexity
export default function CancerHistory({ label, personId, disabled = undefined, config }) {
  const [modalOpened, setModalOpened] = useState(false)
  const [modalConfirmationOpened, setModalConfirmationOpened] = useState(false)
  const [indexDelete, setIndexDelete] = useState(null)
  const [category, setCategory] = useState({})
  const [cancerType, setCancerType] = useState({})
  const [treatmentLocation, setTreatmentLocation] = useState('')
  const [patientAddress, setPatientAddress] = useState('')
  const [qualifiers, setQualifiers] = useState([])
  const [secondBreastCancer, setSecondBreastCancer] = useState()
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const { setLoading } = useContext(Context)
  const { setPersonCancers, removePersonTreatments } = useActions()

  const { persons, probandId } = useSelector(({ questionnaire }) => ({
    persons: questionnaire.persons,
    probandId: questionnaire.probandId,
  }))

  const { cancers = [], treatments = [], sex = '' } = persons[personId]

  const filteredCategories = CancerTypes.CANCER_CATEGORIES.filter((category) => {
    if (!category.genderRestriction) return category

    return category.genderRestriction === sex ? category : null
  })

  const addDiagnosis = () => {
    const cancerIndex = cancers.findIndex(({ id }) => id === cancerType.id)
    const filteredUndefined = qualifiers.filter((item) => item)
    // A little complicated because of `Breast Cancer`
    const newQualifier = () => {
      // 1) if secondBreastCancer is open and then no qualifiers are selected
      if (secondBreastCancer === 'Y' && !filteredUndefined.length)
        return [{ id: uuidv4() }, { id: uuidv4() }]
      // 2) if secondBreastCancer is open and then a single qualifier is selected
      if (secondBreastCancer === 'Y' && filteredUndefined.length === 1)
        return [...filteredUndefined, { id: uuidv4() }]
      // 3-1) if secondBreastCancer is open and then two all qualifiers are selected
      // 3-2) or other cancer category is selected and its own qualifier is also selected
      if (
        (secondBreastCancer === 'Y' && filteredUndefined.length === 2) ||
        (secondBreastCancer !== 'Y' && filteredUndefined.length)
      )
        return [...filteredUndefined]

      // default qualifier
      return [{ id: uuidv4() }]
    }

    const _cancers = [...cancers]

    if (cancerIndex < 0) {
      _cancers.push({
        id: cancerType.id,
        label: cancerType.label,
        isPresent: 'Y',
        treatments: [{ type: 'other', treatmentLocation, patientAddress }],
        qualifiers: newQualifier(),
      })
    } else {
      const updatedCancer = {
        ...cancers[cancerIndex],
        qualifiers: [...cancers[cancerIndex].qualifiers, ...newQualifier()],
        treatments: [{ type: 'other', treatmentLocation, patientAddress }],
      }

      _cancers[cancerIndex] = updatedCancer
    }

    setPersonCancers({ id: personId, value: _cancers })
    setModalOpened(false)
    setSecondBreastCancer(undefined)
    resetValues()
  }

  const handleDeleteCancer = (index = undefined) => {
    const _cancers = [...cancers]
    const _indexDelete = index ?? indexDelete

    // clean up mantle radiation treatment
    const mantleRadiation = treatments.find(({ type }) => type === 'mantleRadiation')
    if (_cancers[_indexDelete]?.id === 'HP:0012189' && mantleRadiation) {
      removePersonTreatments({ id: personId, type: 'mantleRadiation' })
    }
    _cancers.splice(_indexDelete, 1)
    setPersonCancers({ id: personId, value: _cancers })
    setLoading((prevState) => ({
      ...prevState,
      dialog: false,
    }))
    handleClose()
  }

  const handleClose = () => {
    setModalConfirmationOpened(false)
    setIndexDelete(null)
  }

  const confirmDeletion = () => (
    <DialogWindow
      open={modalConfirmationOpened}
      onClose={handleClose}
      handleConfirm={() => handleDeleteCancer()}
      title="Remove Cancer"
      text="Are you sure you want to remove this item?"
      color="warning"
    />
  )

  const renderDiagnosisAdded = () => {
    return cancers.map(
      ({ id: cancerId, label = '', qualifiers = [], treatments = [] }, cancerIndex) => {
        const newQualifiers = []

        // need to deeply clone to separate cancer's qualifiers from this rendering.
        qualifiers.forEach(({ ...arg }) => {
          newQualifiers.push({ ...arg })
        })

        // sort the newQualifiers
        newQualifiers.sort(qualifierSort)

        return (
          <Container key={cancerId}>
            <Card elevation={2} sx={{ borderRadius: 2 }}>
              <CardHeader
                sx={{
                  backgroundColor: (theme) => theme.palette.white.main,
                  py: 2,
                  px: isMobile ? 2 : 3,
                }}
                avatar={<LabelIcon color="primary" />}
                title={CancerTypes.findCancerLabel(cancerId) || label}
                subheader={
                  <Box display="flex" flexDirection="column" gap={0.5} pt={0.5}>
                    {newQualifiers.map(({ numericAgeAtDiagnosis, laterality, id: qualifierId }) => (
                      <Typography
                        key={qualifierId + numericAgeAtDiagnosis + laterality}
                        variant="subtitle2"
                      >
                        Diagnosis Age: <b>{ageText(numericAgeAtDiagnosis, laterality)}</b>
                      </Typography>
                    ))}
                    {treatments.map(({ treatmentLocation, patientAddress }) => [
                      treatmentLocation ? (
                        <Typography key={'ctl-' + treatmentLocation} variant="subtitle2">
                          Treatment Location: <b>{treatmentLocation}</b>
                        </Typography>
                      ) : null,
                      patientAddress ? (
                        <Typography key={'cpa-' + patientAddress} variant="subtitle2">
                          Home Address: <b>{patientAddress}</b>
                        </Typography>
                      ) : null,
                    ])}
                  </Box>
                }
                action={
                  <IconButton
                    color="error"
                    onClick={() => {
                      setIndexDelete(cancerIndex)
                      setModalConfirmationOpened(true)
                    }}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                }
              />
            </Card>
          </Container>
        )
      },
    )
  }

  const resetValues = () => {
    setCategory({})
    setCancerType({})
    setQualifiers([])
    setTreatmentLocation('')
    setPatientAddress('')
  }

  const handleCloseDialog = () => {
    resetValues()
    setModalOpened(false)
  }

  // It is managed by the `index` argument.
  // In this case, the previous filter
  // function should not be implemented here
  // because the number of elements varies in terms of qualifier selection.
  const handleQualifiersChange = (index, attributeValue) => {
    if (!qualifiers[index]) {
      qualifiers[index] = { ...attributeValue, id: uuidv4() }
    } else {
      qualifiers[index] = { ...qualifiers[index], ...attributeValue }
    }

    setQualifiers([...qualifiers])
  }

  const getCancerTypes = (label) => {
    switch (label) {
      case 'Breast':
        return CancerTypes.BREAST_TYPES
      case 'Ovarian':
        return CancerTypes.OVARIAN_TYPES
      case 'Skin':
        return CancerTypes.SKIN_TYPES
      case 'Kidney and urinary tract':
        return CancerTypes.KIDNEY_AND_URINARY_TYPES
      case 'Brain':
        return CancerTypes.BRAIN_TYPES
      case 'Colon (Bowel)':
        return CancerTypes.COLON_TYPES
      default:
        return null
    }
  }

  return (
    <FormLabel label={label} key="addCancer">
      {renderDiagnosisAdded()}
      {confirmDeletion()}
      <Button startIcon={<AddIcon />} disabled={disabled} onClick={() => setModalOpened(true)}>
        Add Diagnosis
      </Button>
      <Dialog open={modalOpened} onClose={() => handleCloseDialog()}>
        <DialogTitle>Add Diagnosis</DialogTitle>
        <DialogContent sx={{ minWidth: isMobile ? '80vw' : '35vw' }}>
          <Container>
            <SearchSelect
              label="Search Cancers"
              value={{ label: category.label || '', value: category.id || '' }}
              options={filteredCategories.map(({ label, id }) => ({ label, value: id }))}
              onChange={(e, newValue) => {
                // search field is reset (x button)
                if (!newValue) {
                  resetValues()

                  return
                }
                const _category = filteredCategories.find(({ id }) => id === newValue.value)

                // same category was selected
                if (newValue.value === category.id) return
                // clear cancer type
                if ('id' in cancerType) {
                  setCancerType({})
                }
                // clear qualifiers
                if (qualifiers.length > 0) {
                  setQualifiers([])
                }
                // clear treatmentLocation
                if (treatmentLocation !== '') {
                  setTreatmentLocation('')
                }
                // clear patientAddress
                if (patientAddress !== '') {
                  setPatientAddress('')
                }
                // set category
                setCategory(_category)
                // other option, custom cancer field
                if (_category.id === 'Other') {
                  setCancerType({ id: 'other', label: '' })

                  return
                }
                // set type to match category if not other
                if (!getCancerTypes(_category.label)) {
                  setCancerType(_category)
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params)
                const otherOption = {
                  label: 'Other',
                  value: 'Other',
                }

                // always keep other option in view
                if (!filtered.find((item) => item.id === otherOption.id)) {
                  filtered.push(otherOption)
                }

                return filtered
              }}
            />
            <SpecificCancerQuestions
              category={category}
              cancerType={cancerType}
              setCancerType={setCancerType}
              setTreatmentLocation={setTreatmentLocation}
              treatmentLocation={treatmentLocation}
              setPatientAddress={setPatientAddress}
              patientAddress={patientAddress}
              qualifiers={qualifiers}
              setQualifiers={setQualifiers}
              handleQualifiersChange={handleQualifiersChange}
              secondBreastCancer={secondBreastCancer}
              setSecondBreastCancer={setSecondBreastCancer}
              getCancerTypes={getCancerTypes}
              isProband={probandId === personId}
              config={config}
            />
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDialog()}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            endIcon={<AddIcon />}
            onClick={() => addDiagnosis()}
            disabled={_.isEmpty(cancerType) || (cancerType.id === 'other' && !cancerType.label)}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </FormLabel>
  )

  function qualifierSort(a, b) {
    // sorting lowest -> highest, unknown always last
    if (a.numericAgeAtDiagnosis && b.numericAgeAtDiagnosis) {
      // both integer values
      return a.numericAgeAtDiagnosis - b.numericAgeAtDiagnosis
    } else if (!a.numericAgeAtDiagnosis && !b.numericAgeAtDiagnosis) {
      // both are unknown
      return 0
    } else {
      // unknown goes last
      return a.numericAgeAtDiagnosis ? -1 : 1
    }
  }
  function ageText(age, laterality) {
    let ageText = ''
    if (age === undefined || age > Number.MAX_VALUE) {
      ageText = 'unknown age'
    } else {
      ageText = age
    }
    if (laterality) {
      ageText += ` (${laterality})`
    }

    return ageText
  }
}
