import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { getLabel, renderContent } from '../../utils/helpers'
import { PersonFieldPaths as paths } from '../../utils/QuestionnaireStateManager'
import Chooser from '../../widgets/Chooser'
import FollowUpQuestions from '../../widgets/FollowUpQuestions'
import FormLabel from '../../widgets/FormLabel'
import IntegerDetails from '../../widgets/IntegerDetails'

const ContraceptivePills = ({ id, patient = 'patient', config }) => {
  const { persons, localization } = useSelector(({ questionnaire, localization }) => ({
    persons: questionnaire.persons,
    localization,
  }))
  const {
    setPersonOralContraceptiveUsage,
    setPersonExtendedCancerRisk,
    removePersonExtendedCancerRisk,
  } = useActions()
  const {
    cancerRiskData: { oralContraceptiveUsage = undefined, extendedCancerRiskDataList = [] } = {},
    sex,
  } = persons[id]
  const ocpYears =
    extendedCancerRiskDataList.find((item) => item.extendedCancerRiskDataType === 'ocpYears') || {}

  if (sex !== 'F') return null

  return (
    <>
      <Chooser
        label={getLabel(localization, paths.ORAL_CONTRACEPTIVE_PILLS_USAGE, patient)}
        onChange={(value) => {
          if (value !== oralContraceptiveUsage) {
            setPersonOralContraceptiveUsage({ id, value })

            if (value === 'never' || value === 'unknown') {
              removePersonExtendedCancerRisk({ id, type: 'ocpYears' })
            }
          }
        }}
        selectedValue={oralContraceptiveUsage}
        patient={patient}
        path={paths.ORAL_CONTRACEPTIVE_PILLS_USAGE}
        orderSchema={['never', 'unknown', 'stopped2', 'usedWithin2']}
      />
      {oralContraceptiveUsage === 'usedWithin2' || oralContraceptiveUsage === 'stopped2' ? (
        <FollowUpQuestions>
          {renderContent({
            renderMap: {
              ocpYears: (
                <FormLabel key="br" label="For how many years total?">
                  <IntegerDetails
                    value={ocpYears.value || ''}
                    onChange={(value) => {
                      setPersonExtendedCancerRisk({ id, type: 'ocpYears', value })
                    }}
                  />
                </FormLabel>
              ),
            },
            config,
          })}
        </FollowUpQuestions>
      ) : null}
    </>
  )
}

export default ContraceptivePills
