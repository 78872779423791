import { Box, Checkbox, FormControlLabel, TextField, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import uuidv4 from 'uuid/v4'
import { useActions } from '../../hooks/useActions'
import { renderContent } from '../../utils/helpers'
import FollowUpQuestions from '../../widgets/FollowUpQuestions'
import FormLabel from '../../widgets/FormLabel'

function RespondentDetails({ respondent, setRespondentPerson, setRespondentNotes, config }) {
  const { firstName = '', lastName = '' } = respondent.person?.name || {}

  return (
    <>
      <Typography sx={{ pb: 3, paddingX: 2 }}>
        If you have been referred because your spouse or relative has died, complete the form with
        their information. For example, please complete the “Your Information” section with your
        relative’s information instead of your own in this situation
      </Typography>
      <FollowUpQuestions>
        Please fill out your personal details
        {renderContent({
          renderMap: {
            // we should consider consolidating first/last to be respondent-name
            respondentFirst: (
              <Box key="res-name" display="flex" gap={3}>
                <FormLabel label="Respondent First Name">
                  <TextField
                    onChange={({ target: { value } }) =>
                      setRespondentPerson({ value: { name: { firstName: value, lastName } } })
                    }
                    value={firstName}
                  />
                </FormLabel>
                <FormLabel label="Respondent Last Name">
                  <TextField
                    onChange={({ target: { value } }) =>
                      setRespondentPerson({ value: { name: { firstName, lastName: value } } })
                    }
                    value={lastName}
                  />
                </FormLabel>
              </Box>
            ),
            respondentReason: (
              <FormLabel
                key="res-reason"
                label={
                  'Reason for completing on behalf of another person and' +
                  ' your relationship to that person'
                }
                fullWidth
              >
                <TextField
                  value={respondent.notes || ''}
                  onChange={({ target: { value } }) => setRespondentNotes({ value })}
                />
              </FormLabel>
            ),
          },
          config,
        })}
      </FollowUpQuestions>
    </>
  )
}

export default function Respondent({ config }) {
  const { respondent = {} } = useSelector(({ questionnaire }) => ({
    respondent: questionnaire.respondent,
  }))
  const { setRespondentPerson, setRespondentNotes, removeRespondentPerson } = useActions()
  const isChecked = !!respondent?.person

  return (
    <>
      <FormControlLabel
        label={
          <Typography variant="subtitle1">
            Are you filling this out on behalf of someone else?
          </Typography>
        }
        control={
          <Checkbox
            checked={isChecked}
            onChange={({ currentTarget: { checked } }) => {
              checked ? addRespondentPerson() : cleanUpRespondentPerson()
            }}
          />
        }
        sx={{ py: 1 }}
      />
      {isChecked ? (
        <RespondentDetails
          respondent={respondent}
          setRespondentPerson={setRespondentPerson}
          setRespondentNotes={setRespondentNotes}
          config={config}
        />
      ) : null}
    </>
  )

  function addRespondentPerson() {
    const id = uuidv4()
    setRespondentPerson({ value: { id, relationshipToProband: 'unknown' } })
  }
  function cleanUpRespondentPerson() {
    removeRespondentPerson()
    setRespondentNotes('')
  }
}
