import AddIcon from '@mui/icons-material/Add'
import { Button } from '@mui/material'
import { Container } from '@mui/system'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import RemoveLoadingHook from '../../hooks/RemoveLoadingHook'
import { useActions } from '../../hooks/useActions'
import FamilyHelper from '../../utils/FamilyHelper'
import { getOffspringStr, renderContent } from '../../utils/helpers'
import FormLabel from '../../widgets/FormLabel'
import MultiPersonCards from '../../widgets/MultiPersonCards'
import BreastFeeding from './BreastFeeding'
import ChildQuestions from './ChildQuestions'

const Children = () => {
  const { addChild } = useActions()
  const { questionnaire, config } = useSelector(({ questionnaire, configuration }) => ({
    questionnaire,
    config: configuration.sectionConfig['your-family']?.subSectionConfig.children.questions || [],
  }))
  const { persons, probandId } = questionnaire

  const [children, setChildren] = useState({
    childPersonIds: [],
    childCount: 0,
  })

  RemoveLoadingHook()

  // on prop updates
  useEffect(() => {
    setChildren(() => ({
      childPersonIds: getChildPersonIds(probandId),
      childCount: getChildCount(),
    }))
  }, [Object.keys(persons).length])

  const { childCount, childPersonIds } = children

  return (
    <Container>
      {renderContent({
        renderMap: {
          breastFeeding: <BreastFeeding key="bf" />,
        },
        config,
      })}
      <FormLabel fullWidth label="Please add your biological children (if applicable)">
        <MultiPersonCards
          personIds={childPersonIds}
          entryRenderer={childQuestions}
          personLabel={personLabel}
          sx={{ py: 2 }}
        />
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          onClick={buttonHandler}
          sx={{ width: 'fit-content' }}
        >
          Add Child
        </Button>
      </FormLabel>
    </Container>
  )

  /**
   * Returns question components needed for MultiPersonCards
   * @returns {JSX}
   */
  function childQuestions(personId) {
    return <ChildQuestions personId={personId} config={config} />
  }

  /**
   * Returns a list of all children of the person specified
   * @returns {String}
   */
  function getChildPersonIds(id) {
    const children = new FamilyHelper(questionnaire).getChildren(id)

    return _.flatten(children).map(({ id: _id }) => _id)
  }

  /**
   * Returns the number of children for the specified person
   * @returns {Number}
   */
  function getChildCount() {
    const count = new FamilyHelper(questionnaire).getChildren(probandId) ?? []

    return count.length
  }
  /**
   * Adds a new child member when button is clicked
   */
  function buttonHandler() {
    addChild({ probandId, count: childCount + 1, targetPersonArray: ['proband'] })
  }
  /**
   * Returns child label if sex is defined
   * @returns { String }
   */
  function personLabel(personId) {
    const sex = persons[personId]?.sex

    return `Patient's ` + (sex ? getOffspringStr(sex) : 'child')
  }
}

export { Children }
