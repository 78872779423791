import { Box, Checkbox, FormControlLabel, TextField } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { PersonProperties, PersonSurgeries } from '../../utils/QuestionnaireStateManager'
import FollowUpQuestions from '../../widgets/FollowUpQuestions'
import FormLabel from '../../widgets/FormLabel'
import IntegerDetails from '../../widgets/IntegerDetails'

let time = null

function RelativeSurgery({
  personId,
  personLabel,
  lastItemUnchecked,
  surgeryType,
  probandProperty,
}) {
  const { setPersonSurgeries, removePersonSurgeries, setPersonPropertiesIsPresent } = useActions()
  const {
    relative,
    probandId,
    probandProperties = [],
  } = useSelector(({ questionnaire }) => ({
    relative: questionnaire.persons[personId],
    probandId: questionnaire.probandId,
    probandProperties: questionnaire.persons[questionnaire.probandId].properties,
  }))

  const { age = '', reason = '' } =
    relative.surgeries.find(({ type }) => type === surgeryType) || {}

  const isChecked = !!relative.surgeries.find((i) => i.type === surgeryType)

  return (
    <Box>
      <FormControlLabel
        label={personLabel}
        control={<Checkbox checked={isChecked} onChange={handleCheckbox} />}
      />
      {isChecked && (
        <FollowUpQuestions>
          <FormLabel label={'Age removed?'}>
            <IntegerDetails onChange={handleAge} value={age} />
          </FormLabel>
          <FormLabel label={'What was the reason?'}>
            <TextField
              fullWidth
              rows={2}
              multiline={true}
              inputProps={{ maxLength: 255 }}
              onChange={handleTextArea}
              value={reason}
            />
          </FormLabel>
        </FollowUpQuestions>
      )}
    </Box>
  )

  /**
   * Create, delete, and update relative and proband properties with checkboxes.
   * @param {InputEvent} e Checkbox `change` event.
   **/
  function handleCheckbox(e) {
    const hasProbandProperty =
      probandProperties.find(({ type }) => type === probandProperty)?.isPresent !== 'Y'

    // on checkbox selected
    if (e.target.checked) {
      if (hasProbandProperty) {
        setPersonPropertiesIsPresent({
          id: probandId,
          type: probandProperty,
          value: 'Y',
        })
      }
      setPersonSurgeries({
        id: personId,
        surgeryType,
        type: 'type',
        value: surgeryType,
      })

      return
    }
    // on checkbox deselected
    if (lastItemUnchecked) {
      setPersonPropertiesIsPresent({
        id: probandId,
        type: probandProperty,
        value: 'N',
      })
    }
    removePersonSurgeries({
      id: personId,
      type: surgeryType,
    })
  }

  /**
   * Create, delete, and update relative and proband properties with checkboxes.
   * @param {number} value IntegerDetails' number value
   **/
  function handleAge(value) {
    setPersonSurgeries({
      id: personId,
      surgeryType,
      type: 'age',
      value,
    })
  }
  /**
   * Create, delete, and update relative and proband properties with checkboxes.
   * @param {InputEvent} e Checkbox `change` event.
   **/
  function handleTextArea({ target: { value } }) {
    if (time) {
      clearTimeout(time)
    }

    time = setTimeout(() => {
      setPersonSurgeries({
        id: personId,
        surgeryType,
        type: 'reason',
        value,
      })
    }, 5)
  }
}

function Hysterectomy({ ...props }) {
  return (
    <RelativeSurgery
      surgeryType={PersonSurgeries.HYSTERECTOMY}
      probandProperty={PersonProperties.HAS_RELATIVE_HAVE_HYSTERECTOMY}
      {...props}
    />
  )
}

function Oophorectomy({ ...props }) {
  return (
    <RelativeSurgery
      surgeryType={PersonSurgeries.OOPHORECTOMY}
      probandProperty={PersonProperties.HAS_RELATIVE_REMOVED_OVARIES}
      {...props}
    />
  )
}

export { Hysterectomy, Oophorectomy }
