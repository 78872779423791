import { Main } from './Main'
import { navigateToErrorScreen } from './utils/errors'

export class Auth extends Main {
  static baseUrl = this.getRoot()

  static async login({
    accessToken = '',
    success,
    dateOfBirth = { day: '', month: '', year: '' },
    failure = undefined,
    redirect = undefined,
  }) {
    const url = `${this.baseUrl}/login`

    try {
      const response = await fetch(url, {
        method: 'POST',
        url,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          accessToken,
          dateOfBirth,
        }),
      })

      if (!response?.ok) {
        const error = await response.json()
        throw new Error(error.status, { cause: error.message })
      }

      // Control SessionTimeout
      if (!redirect && success) {
        success()
      }
      // Login with token
      if (redirect && (await success())) {
        await redirect()
      }
    } catch (err) {
      const { message: status = '', cause = '' } = err
      if (failure && !failure(status)) {
        return
      }

      navigateToErrorScreen({
        fetchType: 'login',
        status,
        cause,
      })
    }
  }

  static async logout({ addToast = undefined } = {}) {
    try {
      const { ok = true } = (await fetch('/logout', { method: 'POST' })) || {}

      if (!ok) {
        throw new Error('Unable to logout.')
      }

      if (addToast) {
        addToast('success', 'info', 'Successfully Logged Out')
      }
    } catch (err) {
      if (addToast) {
        addToast('error', 'danger', err.message)
      }

      navigateToErrorScreen({
        fetchType: 'logout',
        status: err.message,
      })
    }
  }

  static async getSession() {
    const url = `${this.baseUrl}/questionnaire/current-session`

    try {
      const currentStatus = await (await fetch(url, { method: 'GET' })).json()
      const { errors, error, status, message } = currentStatus || {}

      if (errors || error) {
        throw new Error(status, { cause: message || error || errors?.[0]?.detail || '*' })
      }

      return currentStatus
    } catch (err) {
      navigateToErrorScreen({
        fetchType: 'getSession',
        status: err.message,
        cause: err.cause,
      })
    }
  }
}
