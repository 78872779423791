import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import IdleTimer from 'react-idle-timer'
import { useHistory } from 'react-router-dom'
import { Auth } from '../api/Auth'
import Context from '../utils/context/Context'
import DialogWindow from '../widgets/DialogWindow'

const SessionTimeout = ({ children }) => {
  const date = new Date()
  const history = useHistory()

  const timeoutConstant = {
    SESSION_TIMEOUT_MIL_SECONDS: 1000 * 60 * 20,
    // Session refreshes in the front end.
    // SESSION_TIMEOUT_MIL_SECONDS + WARNING_DURATION_SECONDS
    LOGOUT_MIL_SECONDS: 1000 * 60 * 22,
    // Login again before the session expired in the backend.
    LOGIN_MIL_SECONDS: 1000 * 60 * 29,
    WARNING_DURATION_SECONDS: 120,
  }

  const {
    authState: { isAuthenticated },
    setTimeoutModal,
    timeoutModal,
    setLoading,
  } = useContext(Context)

  const [timeoutCountdown, setTimeoutCountdown] = useState(timeoutConstant.WARNING_DURATION_SECONDS)
  const [hasMultiTabs, setHasMultiTabs] = useState(false)
  const idleTimer = useRef(null)
  const countdownInterval = useRef(null)

  const broadcastChannel = useCallback(() => {
    return new BroadcastChannel('re-login-ch')
    // eslint-disable-next-line
  }, [hasMultiTabs])

  useEffect(async () => {
    if (timeoutCountdown <= 0) {
      await handleLogout()
    }

    // eslint-disable-next-line
  }, [timeoutCountdown])

  useEffect(() => {
    // broadcast message for the other tabs in the browser.
    const channel = broadcastChannel()

    channel.postMessage({
      hasMultiTabs: true,
    })

    channel.onmessage = async (event) => {
      if (event.data.hasMultiTabs) {
        await handleContinue()
      }
    }

    // eslint-disable-next-line
  }, [broadcastChannel])

  const clearSessionInterval = () => {
    if (countdownInterval.current) {
      clearInterval(countdownInterval.current)
    }
  }

  const handleLogout = async () => {
    setTimeoutModal(() => ({
      type: '',
      open: false,
    }))

    clearSessionInterval()

    await Auth.logout()

    await history.push('/user-logout', {
      message: `Your session has expired. To protect your information,
      you have been logged out.`,
    })

    setTimeoutModal(() => ({
      type: 'timedOut',
      open: true,
    }))
  }

  const handleContinue = async () => {
    const { reset } = idleTimer.current

    await Auth.login({
      success() {
        setTimeoutModal({
          type: '',
          open: false,
        })

        clearSessionInterval()
        setTimeoutCountdown(timeoutConstant.WARNING_DURATION_SECONDS)
        reset()
      },
    })
  }

  const onAction = async () => {
    const { getElapsedTime, reset } = idleTimer.current

    // The user should login every 29 minutes
    // before the session is expired in the backend.
    if (getElapsedTime() > timeoutConstant.LOGIN_MIL_SECONDS) {
      await Auth.login({
        success: reset,
        failure: handleLogout,
      })
    }
  }

  const onActive = async () => {
    const { getLastActiveTime } = idleTimer.current
    const { LOGOUT_MIL_SECONDS } = timeoutConstant

    // Forcefully logout when the idle time is over 22 minutes
    // including `lock`, `sleep`, or `hibernate` time in the machine
    // and the time the user is working on another apps in the machine
    // or tabs at the browser
    if (getLastActiveTime() < date.getTime() - LOGOUT_MIL_SECONDS) {
      await handleLogout()
    }
  }

  const onIdle = () => {
    if (isAuthenticated && !timeoutModal.open) {
      setTimeoutModal({
        type: 'warning',
        open: true,
      })
    }

    countdownInterval.current = setInterval(() => {
      setTimeoutCountdown((count) => count - 1)
    }, 1000)
  }

  return (
    <>
      <IdleTimer
        ref={idleTimer}
        timeout={timeoutConstant.SESSION_TIMEOUT_MIL_SECONDS}
        onActive={onActive}
        onAction={onAction}
        onIdle={onIdle}
        // startManually
        // `crossTab` is required only for the `onIdle` function
        crossTab={{
          emitOnAllTabs: true,
          type: 'broadcastChannel',
        }}
      >
        {children}
      </IdleTimer>
      <DialogWindow
        open={timeoutModal.open && timeoutModal.type === 'warning'}
        onClose={async () => {
          await handleContinue()
            // handling the other browser tabs
            .then(() => {
              setHasMultiTabs(!hasMultiTabs)
            })
            .catch(() => {})
        }}
        handleConfirm={async () => {
          await handleContinue()
            // handling the other browser tabs
            .then(() => {
              setHasMultiTabs(!hasMultiTabs)
              setLoading((prevState) => ({
                ...prevState,
                dialog: false,
              }))
            })
            .catch(() => {})
        }}
        title="Session expiring"
        text="Your session will expire soon due to inactivity."
        color="warning"
        primaryBtn={`Resume in ${timeoutCountdown}`}
        noSecondary
      />
    </>
  )
}

export default SessionTimeout
