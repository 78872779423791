import AddIcon from '@mui/icons-material/Add'
import { Box, Button } from '@mui/material'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import RemoveLoadingHook from '../../hooks/RemoveLoadingHook'
import { useActions } from '../../hooks/useActions'
import FamilyHelper from '../../utils/FamilyHelper'
import FormLabel from '../../widgets/FormLabel'
import MultiPersonCards from '../../widgets/MultiPersonCards'
import SiblingQuestions from './SiblingQuestions'

function Siblings({ targetPerson, resolvePersonId = undefined }) {
  let resolvePersonIdCreate

  if (resolvePersonId) {
    resolvePersonIdCreate = _.partial(resolvePersonId, true)
  }
  const familyPath = {
    proband: 'your-family',
    mother: 'mothers-family',
    father: 'fathers-family',
  }[targetPerson]
  const { setSiblings } = useActions()
  const questionnaire = useSelector((state) => state.questionnaire)
  const config = useSelector(
    (state) => state.configuration.sectionConfig[familyPath].subSectionConfig.siblings.questions,
  )
  const [siblingPersonIds, setSiblingPersonIds] = useState([])
  const { probandId, persons } = questionnaire
  const isProband = targetPerson === 'proband'
  const designatedPersonId = !isProband ? getPersonId() : probandId

  RemoveLoadingHook()

  useEffect(() => {
    setSiblingPersonIds([...getSiblingPersonIds()])
  }, [Object.keys(persons).length])

  const renderSiblingEntry = (personId) => {
    return (
      <SiblingQuestions
        personId={personId}
        isHalfSibling={isHalfSibling(personId)}
        resolvePersonId={designatedPersonId}
        isProband={isProband}
        config={config}
      />
    )
  }

  return (
    <FormLabel label={isProband ? 'Please add your siblings' : "Please add this person's siblings"}>
      <MultiPersonCards
        personIds={siblingPersonIds}
        entryRenderer={renderSiblingEntry}
        personLabel={(personId) => getPersonLabel(personId)}
        sx={{ pt: 2 }}
      />
      <Box pt={2} display="flex" gap={2} flexWrap="wrap">
        {[
          { isHalf: false, label: 'Add Sibling' },
          { isHalf: true, label: 'Add Half-Sibling' },
        ].map(({ isHalf, label }, index) => (
          <Button
            key={label}
            startIcon={<AddIcon />}
            onClick={(e) => buttonHandler(isHalf)}
            sx={{ width: 'fit-content' }}
            variant="contained"
          >
            {label}
          </Button>
        ))}
      </Box>
    </FormLabel>
  )
  /**
   * Adds a new sibling when button is clicked
   * @param { Boolean } isHalf
   */
  function buttonHandler(isHalf) {
    const targetPersonArray = ['proband']
    if (['mother', 'father'].includes(targetPerson)) {
      targetPersonArray.push(targetPerson)
    }

    setSiblings({
      designatedPersonId: resolvePersonIdCreate || probandId,
      numberSharedParents: isHalf ? 'half' : 'full',
      targetPersonArray,
    })
  }
  /**
   * Returns a list of sibling ids
   * @returns {Array<string>} The current or updated input value.
   */
  function getSiblingPersonIds() {
    const siblingPersons = _.values(new FamilyHelper(questionnaire).getSiblings(designatedPersonId))

    // Still not understanding this code. What if it returns `null`?
    // TODO: need to verify it. If it returns `null`, need to throw an `Error`.
    return siblingPersons.map((item) => item.person?.id ?? null)
  }
  /**
   * Returns if a person is a half sibling
   * @param {String} personId
   * @returns {Boolean}
   */
  function isHalfSibling(personId) {
    const { relationshipToProband = '' } = persons[personId] || {}

    return relationshipToProband.includes('Half')
  }
  /**
   * Get the id of the person if not the proband
   * @returns {String}
   */
  function getPersonId() {
    if (!isProband) {
      return resolvePersonId(false, questionnaire)
    }

    return null
  }
  /**
   * Returns the label for person cards
   * @param {String} personId
   * @returns {String}
   */
  function getPersonLabel(personId) {
    const _targetPerson = isProband ? "Patient's " : _.capitalize(targetPerson) + `'s `
    const siblingSexStr = {
      M: 'brother',
      F: 'sister',
      U: 'sibling',
    }[persons[personId]?.sex || 'U']

    return _targetPerson + (isHalfSibling(personId) ? 'half-' : '') + siblingSexStr
  }
}

export { Siblings }
