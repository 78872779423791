import { Box, Typography } from '@mui/material'
import React from 'react'

const InstructionList = ({ title, description, ...props }) => {
  // if using regex here it must adhere to sonarcloud's very strict standards
  const _title = title.replace('<b>', '').replace('</b>', '')

  return (
    <Box mb={3} {...props}>
      <Typography variant="h3" pb={0.5}>
        {_title}
      </Typography>
      <Typography>{description}</Typography>
    </Box>
  )
}

export default InstructionList
