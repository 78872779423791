import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../hooks/useActions'
import { getLabel } from '../utils/helpers'
import FormLabel from './FormLabel'

const renderMenuItems = (valueRange, dateType) => {
  const options = [...Array(valueRange[1] - valueRange[0] + 1)].map((_, i) =>
    (valueRange[0] + i).toString(),
  )
  // reverse options if years
  if (dateType === 'year') {
    options.reverse()
  }

  return options.map((option) => (
    <MenuItem key={option} value={option}>
      {option}
    </MenuItem>
  ))
}

export const DOBInputs = ({ value, label, dateType, valueRange, onChange, disabled }) => {
  return (
    <FormControl key={'dob-' + dateType} fullWidth>
      <InputLabel
        disabled={disabled}
        sx={(theme) => ({
          color: theme.palette.primary.main,
          fontWeight: '500',
        })}
      >
        {label}
      </InputLabel>
      <Select
        label={label}
        disabled={disabled}
        value={value ?? ''}
        onChange={onChange}
        // limits the height of the dropdown
        MenuProps={{ PaperProps: { style: { maxHeight: 250 } } }}
      >
        {renderMenuItems(valueRange, dateType)}
      </Select>
    </FormControl>
  )
}

const DateOfBirth = ({ id }) => {
  const { localization, dateOfBirth, isProband } = useSelector(
    ({ localization, questionnaire }) => ({
      localization,
      // needs optional chain for relatives created with resolvePersonIdWithCreate
      dateOfBirth: questionnaire.persons[id]?.dateOfBirth ?? {},
      isProband: questionnaire.probandId === id,
    }),
  )
  const { setPersonDateOfBirth } = useActions()

  const { day, month, year } = dateOfBirth
  const inputProps = [
    {
      value: year,
      label: 'Year',
      dateType: 'year',
      valueRange: [1850, new Date().getFullYear()],
      disabled: false,
    },
    { value: month, label: 'Month', dateType: 'month', valueRange: [1, 12], disabled: !year },
    { value: day, label: 'Day', dateType: 'day', valueRange: [1, getDays()], disabled: !month },
  ]

  if (isProband) {
    return null
  }

  return (
    <FormLabel label={getLabel(localization, 'dateOfBirth')}>
      <Box display="flex" gap={1} maxWidth={350}>
        {inputProps.map(({ value, label, dateType, valueRange, disabled }) => (
          <DOBInputs
            key={'dob-' + dateType}
            value={value}
            label={label}
            dateType={dateType}
            valueRange={valueRange}
            disabled={disabled}
            onChange={({ target: { value } }) => {
              setPersonDateOfBirth({ id, dateType, value })
              if (day && dateType !== 'day') {
                // pass new values here as the redux store
                // is not updated before this function
                validateDay({ dateType, value })
              }
            }}
          />
        ))}
      </Box>
    </FormLabel>
  )
  // update day if month/year is updated and it is out of range
  function validateDay({ dateType, value }) {
    const isYear = dateType === 'year'
    const daysInMonth = new Date(isYear ? value : year, isYear ? month : value, 0).getDate()
    if (parseInt(day) > daysInMonth) {
      setPersonDateOfBirth({ id, dateType: 'day', value: daysInMonth })
    }
  }
  // get the number of days in the month selected
  function getDays() {
    const daysInMonth = new Date(year || 2000, month, 0).getDate()

    return daysInMonth || 31
  }
}

export default DateOfBirth
