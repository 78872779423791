import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../hooks/useActions'
import Chooser from './Chooser'

const SexAssignedAtBirth = ({ id, label = undefined, clearSexSpecificQuestions = undefined }) => {
  const sex = useSelector(({ questionnaire }) => questionnaire.persons[id]?.sex)
  const { setPersonSex } = useActions()

  return (
    <Chooser
      label={label || 'Sex assigned at birth'}
      orderSchema={['M', 'F']}
      path="sex"
      onChange={
        clearSexSpecificQuestions
          ? (value) => {
              if (sex !== value) clearSexSpecificQuestions(value)
            }
          : handleClick
      }
      selectedValue={sex}
    />
  )

  /**
   * set person sex on button click
   */
  function handleClick(value) {
    // Need to block the function when the user clicks the same sex in the buttons.
    if (value !== sex) {
      setPersonSex({ id, value })
    }
  }
}

export default SexAssignedAtBirth
