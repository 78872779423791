import { Container } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import RemoveLoadingHook from '../../hooks/RemoveLoadingHook'
import { renderContent } from '../../utils/helpers'
import AdditionalNote from './AdditionalNote'
import AdditionalRelatives from './AdditionalRelatives'

const AdditionalInformation = () => {
  const { config } = useSelector(({ configuration }) => ({
    config: configuration.sectionConfig['additional-info']?.questions || [],
  }))
  const renderMap = {
    additionalInfoShare: <AdditionalNote key="ai" type="additionalInformation" />,
    healthProblems: <AdditionalNote key="ahp" type="additionalHealthProblems" />,
    relevantInfo: <AdditionalNote key="ri" type="relevantInfo" />,
    additionalRelativeConditions: <AdditionalRelatives key="ar" />,
  }
  const content = []

  renderContent({ renderMap, config, content })

  RemoveLoadingHook()

  return <Container>{content}</Container>
}

export { AdditionalInformation }
