import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../hooks/useActions'
import { getLabel } from '../utils/helpers'
import { PersonFieldPaths } from '../utils/QuestionnaireStateManager.js'
import ConditionalSection from './ConditionalSection'
import DeathDetails from './DeathDetails'

export default function LifeStatus({ personId, config }) {
  const {
    alive = '',
    localization,
    causeOfDeath = '',
    ageOfDeath = '',
    postMortemPerformed = '',
  } = useSelector(({ questionnaire, localization }) => ({
    ...(questionnaire.persons[personId]?.lifeStatus || {}),
    localization,
  }))

  const { removePersonLifeStatus, setPersonLifeStatus } = useActions()
  const { ALIVE_STATUS } = PersonFieldPaths

  return (
    <ConditionalSection
      label={getLabel(localization, ALIVE_STATUS)}
      showWhenCondition="N"
      conditionState={alive}
      onConditionStateChange={(value) => {
        if (value !== alive) {
          setPersonLifeStatus({ id: personId, type: 'alive', value })
        }
      }}
      orderSchema={['Y', 'N', 'U']}
      cleanUpChildQuestions={() => {
        causeOfDeath && removePersonLifeStatus({ id: personId, type: 'causeOfDeath' })
        ageOfDeath !== undefined && removePersonLifeStatus({ id: personId, type: 'ageOfDeath' })
        postMortemPerformed && removePersonLifeStatus({ id: personId, type: 'postMortemPerformed' })
      }}
    >
      <DeathDetails
        {...{ personId, causeOfDeath, ageOfDeath, postMortemPerformed, localization, config }}
      />
    </ConditionalSection>
  )
}
