import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import ancestryOptions from '../../utils/AncestryOptions'
import { getLabel } from '../../utils/helpers'
import SearchSelect from '../../widgets/SearchSelect'

const AncestrySelect = ({ side }) => {
  const { setParentsAncestries } = useActions()

  const { probandId, persons, localization } = useSelector(({ questionnaire, localization }) => ({
    probandId: questionnaire.probandId,
    persons: questionnaire.persons,
    localization,
  }))

  /**
   * Returns formatted list of objects for use in the MUI AutoComplete
   * @returns {Object}
   */
  const convertAncestryOptions = (ancestryOptions) => {
    return ancestryOptions ? ancestryOptions.map((value) => ({ label: value, value })) : []
  }

  /**
   * Updates ancestry values via stateManager
   * @param {Object} newValues
   * @param {String} side
   * @returns {Object}
   */
  const handleAncestryChange = (newValues, side) => {
    setParentsAncestries({
      id: probandId,
      parentSide: side,
      value: newValues.map(({ value }) => value),
    })
  }

  /**
   * Returns ancestry list for the specified person
   * @returns {Array<String>}
   */
  const getAncestryBySide = (side) => {
    const { ancestry = {} } = persons[probandId]

    return ancestry[side] || []
  }

  return (
    <SearchSelect
      label={getLabel(localization, `ancestry.${side}`, 'patient')}
      value={convertAncestryOptions(getAncestryBySide(side))}
      options={convertAncestryOptions(ancestryOptions)}
      onChange={(_, newValues) => {
        handleAncestryChange(newValues, side)
      }}
      multiple
    />
  )
}

export default AncestrySelect
