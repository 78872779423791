import { Alert } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { getLabel } from '../../utils/helpers'
import { PersonFieldPaths } from '../../utils/QuestionnaireStateManager'
import ConditionalSection from '../../widgets/ConditionalSection'

const Adoption = ({ personId, targetPerson, type, ...props }) => {
  const { setPersonAdopted } = useActions()
  const { persons, localization } = useSelector(({ questionnaire: { persons }, localization }) => ({
    persons,
    localization,
  }))

  const { adopted = '' } = persons[personId] || {}

  let label = getLabel(localization, PersonFieldPaths.IS_ADOPTED, 'patient')

  if (targetPerson === 'mother') {
    label = getLabel(localization, PersonFieldPaths.IS_MOTHER_ADOPTED, 'patient')
  } else if (targetPerson === 'father') {
    label = getLabel(localization, PersonFieldPaths.IS_FATHER_ADOPTED, 'patient')
  }

  return (
    <>
      <ConditionalSection
        label={label}
        conditionState={adopted}
        onConditionStateChange={(value) => {
          if (value !== adopted) {
            setPersonAdopted({ id: personId, value })
          }
        }}
        orderSchema={['Y', 'N']}
        cleanUpChildQuestions={() => null}
        {...props}
      />
      {type === 'proband' && adopted === 'Y' && (
        <Alert severity="info">
          The following questions relate to the health history of your biological&nbsp; relatives.
          You can skip the questions if you do not have this information.{' '}
        </Alert>
      )}
    </>
  )
}

export default Adoption
