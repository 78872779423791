import { Box, TextField, useMediaQuery } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../hooks/useActions'
import { getLabel, renderContent, charRestriction } from '../utils/helpers'
import { PersonFieldPaths } from '../utils/QuestionnaireStateManager.js'
import FormLabel from './FormLabel'

const { FIRST_NAME, LAST_NAME } = PersonFieldPaths

let time = null
/**
 * Selector for indicating twin of proband
 * @param {function} action - redux action for setting person name
 * @param {string} id - person id
 * @returns {function} - returns debounce onChange function for textFields
 **/
const handleSetNames =
  ({ action, id }) =>
  ({ target: { value } }) => {
    if (time) clearTimeout(time)
    time = setTimeout(() => {
      action({
        id,
        value,
      })
    }, 5)
  }

export const Name = ({ type, id, isProband, localization }) => {
  const {
    setPersonFirstName,
    setPersonLastName,
    setPersonPreviousFirstName,
    setPersonPreviousLastName,
  } = useActions()
  const action = {
    previousNameFirst: setPersonPreviousFirstName,
    previousNameLast: setPersonPreviousLastName,
    nameFirst: setPersonFirstName,
    nameLast: setPersonLastName,
  }
  const {
    name: { firstName = '', lastName = '' } = {},
    previousName: { previousFn = '', previousLn = '' } = {},
  } = useSelector(({ questionnaire: { persons } }) => {
    const person = persons[id] || {}

    return {
      name: person.name,
      previousName: {
        previousFn: person.previousName?.firstName,
        previousLn: person.previousName?.lastName,
      },
    }
  })
  const value = {
    previousNameFirst: previousFn,
    previousNameLast: previousLn,
    nameFirst: firstName,
    nameLast: lastName,
  }
  const labelField = {
    previousNameFirst: FIRST_NAME,
    previousNameLast: LAST_NAME,
    nameFirst: FIRST_NAME,
    nameLast: LAST_NAME,
  }
  const isPrevious = type === 'previousNameFirst' || type === 'previousNameLast'

  return action && value && labelField ? (
    <FormLabel
      label={
        (isPrevious ? 'Previous ' : '') +
        getLabel(localization, labelField[type]) +
        (isProband ? ' *' : '')
      }
    >
      <TextField
        name={type}
        onChangeCapture={(e) => {
          e.target.value = charRestriction(e.target.value)
        }}
        onChange={handleSetNames({ action: action[type], id })}
        value={value[type]}
      />
    </FormLabel>
  ) : null
}

export const PreviousName = ({ id, localization }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))

  return (
    <Box display="flex" gap={3} flexWrap={isMobile && 'wrap'}>
      <Name type={'previousNameFirst'} id={id} localization={localization} />
      <Name type={'previousNameLast'} id={id} localization={localization} />
    </Box>
  )
}

function FullName({ id, isProband, localization, config }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const nameProps = { id, isProband, localization }

  return (
    <>
      <Box display="flex" gap={3} flexWrap={isMobile && 'wrap'}>
        {renderContent({
          renderMap: { nameFirst: <Name key="fn" type={'nameFirst'} {...nameProps} /> },
          config,
        })}
        {renderContent({
          renderMap: { nameLast: <Name key="ln" type={'nameLast'} {...nameProps} /> },
          config,
        })}
      </Box>
    </>
  )
}

export default FullName
