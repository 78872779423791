import React from 'react'
import ConditionalSection from '../../widgets/ConditionalSection'

const ElevatedPSA = ({ ...props }) => {
  return (
    <ConditionalSection
      showWhenCondition="Y"
      orderSchema={['Y', 'N', 'U']}
      cleanUpChildQuestions={() => null}
      {...props}
    />
  )
}

export default ElevatedPSA
