import { Typography } from '@mui/material'
import React from 'react'
import Chooser from '../../widgets/Chooser'

const Twins = ({ ...props }) => {
  return (
    <Chooser orderSchema={['Y', 'N', 'U']} path="yesNoUnknown" {...props}>
      <Typography variant="subtitle2">
        (If yes, please choose &ldquo;sex assigned at birth&rdquo; above)
      </Typography>
    </Chooser>
  )
}

export default Twins
