import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../hooks/useActions'
import Chooser from './Chooser'
import ConditionalSection from './ConditionalSection'

const GenderIdentity = ({ isGenderDifferent, updateGenderDifferent, patient }) => {
  const { persons, probandId } = useSelector(({ questionnaire }) => questionnaire)
  const { removePersonProperty, setPersonGenderIdentity } = useActions()
  const { sex = '', genderIdentity = '' } = persons[probandId]

  return (
    <ConditionalSection
      label="Is your gender identity different than this?"
      showWhenCondition="Y"
      orderSchema={['Y', 'N']}
      conditionState={isGenderDifferent}
      onConditionStateChange={updateGenderDifferent}
      cleanUpChildQuestions={() => {
        removePersonProperty({ id: probandId, type: 'genderIdentity' })
      }}
    >
      <Chooser
        label="What is your current identity?"
        selectedValue={genderIdentity}
        onChange={(value) => {
          if (genderIdentity !== value) {
            setPersonGenderIdentity({ id: probandId, value })
          }
        }}
        orderSchema={['M', 'F', 'nonBinary', 'O'].filter((_sex) => sex !== _sex)}
        patient={patient}
        path="genderIdentity"
      />
    </ConditionalSection>
  )
}

export default GenderIdentity
