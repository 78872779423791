import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { getLabel, renderContent } from '../../utils/helpers'
import { PersonFieldPaths as paths } from '../../utils/QuestionnaireStateManager'
import Chooser from '../../widgets/Chooser'
import FollowUpQuestions from '../../widgets/FollowUpQuestions'
import FormLabel from '../../widgets/FormLabel'
import IntegerDetails from '../../widgets/IntegerDetails'

export const AgeAtMenopause = ({ label, ageAtMenopause, onChange }) => {
  return (
    <FormLabel label={label}>
      <IntegerDetails
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
            max: 150,
          },
        }}
        value={ageAtMenopause}
        onChange={onChange}
      />
    </FormLabel>
  )
}

const Menopause = ({ id, patient = 'patient', config }) => {
  const {
    questionnaire: { persons },
    localization,
  } = useSelector(({ questionnaire, localization }) => ({ questionnaire, localization }))
  const { setPersonMenopauseStatus, setPersonAgeAtMenopause } = useActions()
  const { cancerRiskData: { menopauseStatus = '', ageAtMenopause = '' } = {}, sex } = persons[id]

  return sex === 'F' ? (
    <>
      <Chooser
        label={getLabel(localization, paths.MENOPAUSE_STATUS, 'patient')}
        onChange={(value) => {
          if (value !== menopauseStatus) {
            setPersonMenopauseStatus({ id, value })
            if (value === 'N' || value === 'unknown') {
              setPersonAgeAtMenopause({ id, value: undefined })
            }
          }
        }}
        selectedValue={menopauseStatus}
        patient={patient}
        path={paths.MENOPAUSE_STATUS}
        orderSchema={['Y', 'inMenopause', 'N', 'unknown']}
      />
      {menopauseStatus === 'Y' || menopauseStatus === 'inMenopause' ? (
        <FollowUpQuestions>
          {renderContent({
            renderMap: {
              menopauseAge: (
                <AgeAtMenopause
                  key="agm"
                  label={getLabel(localization, paths.AGE_AT_MENOPAUSE_LABEL, 'patient')}
                  ageAtMenopause={ageAtMenopause}
                  onChange={(value) => {
                    if (value !== ageAtMenopause) {
                      setPersonAgeAtMenopause({ id, value })
                    }
                  }}
                />
              ),
            },
            config,
          })}
        </FollowUpQuestions>
      ) : null}
    </>
  ) : null
}

export default Menopause
