import CloseIcon from '@mui/icons-material/Close'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import MenuIcon from '@mui/icons-material/Menu'
import {
  // Alert,
  Box,
  Collapse,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material'
import _ from 'lodash'
import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import { sendMetricsData, sendStateData, submitStateData } from '../redux/actionCreators'
import { theme } from '../theme'
import Context from '../utils/context/Context'
import { getLabel } from '../utils/helpers'
import { EventType, NavigationPaths } from '../utils/QuestionnaireStateManager.js'
import AppVersion from '../widgets/AppVersion'
import CollapsibleError from '../widgets/CollapsibleError'
import DialogWindow from '../widgets/DialogWindow'
import CurrentStep from './CurrentStep'

class Navigation extends Component {
  static contextType = Context
  constructor(props) {
    super(props)

    this.scrollBody = createRef(null)

    this.state = {
      selectedNavId: 'introduction',
      expandedNodeId: null,
      burgerMenuOpened: false,
    }
  }

  renderDrawItem(nodeItem) {
    const { isSelected, isExpanded, label, type, key = undefined } = nodeItem
    const isFolder = type === 'folder'
    const { loading } = this.context
    const expandIcon = () => {
      if (!isFolder) return null

      return isExpanded ? <ExpandLess /> : <ExpandMore />
    }

    return (
      <ListItemButton
        disabled={!!loading?.save}
        selected={isSelected}
        onClick={async () => {
          const _node = { ...nodeItem }
          _node.eventType = EventType.PAGE_JUMP
          await this.saveAndGoToStep(_node)
        }}
        key={key || key.toString()}
      >
        <ListItemText primary={label} />
        {expandIcon()}
      </ListItemButton>
    )
  }

  render() {
    const { localization } = this.props
    const errorMessage = this.context.dialogBox.errorMessage

    return (
      <Box
        sx={{
          display: 'flex',
          margin: '0',
          width: '100vw',
          height: '100vh',
        }}
      >
        <Box
          sx={{
            display: 'none',
            padding: '10px',
            position: 'fixed',
            top: '0',
            right: '0',
            zIndex: '10',
            [theme.breakpoints.down('md')]: {
              display: 'initial',
            },
          }}
        >
          <IconButton
            color="primary"
            onClick={() => this.setState({ burgerMenuOpened: !this.state.burgerMenuOpened })}
          >
            {this.state.burgerMenuOpened ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        </Box>
        <Drawer
          variant="permanent"
          anchor="left"
          sx={{
            [theme.breakpoints.down('md')]: {
              ...(this.state.burgerMenuOpened
                ? {
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    height: '100vh',
                    width: '100vw',
                    maxWidth: '100vw',
                    background: theme.palette.white.main,
                    padding: '0',
                    zIndex: '2',
                  }
                : {
                    display: 'none',
                  }),
            },
            '& .MuiDrawer-paper': {
              paddingTop: {
                md: '24px',
                xs: '48px',
              },
            },
          }}
        >
          <List>
            {this.getStepsAndFolders()
              .map((step, i) => this.renderSidebarNavItem(step, i))
              .map((navItem) => {
                const { isExpanded, type, key } = navItem

                return type === 'folder' ? (
                  <Box key={key}>
                    {this.renderDrawItem({ ...navItem })}
                    <Collapse
                      in={isExpanded}
                      sx={{
                        '& .MuiListItemButton-root': {
                          paddingLeft: '35px',
                        },
                        '& .MuiCollapse-wrapper': {
                          margin: '-10px 0',
                        },
                      }}
                    >
                      {navItem.childNodes.map((childNode) =>
                        this.renderDrawItem({ ...childNode, key: childNode.key }),
                      )}
                    </Collapse>
                  </Box>
                ) : (
                  this.renderDrawItem({ ...navItem, key })
                )
              })}
          </List>
          <Box flexGrow={1}></Box>
          <AppVersion />
        </Drawer>
        <CurrentStep
          scrollRef={this.scrollBody}
          addToast={this.props.addToast}
          loadingControls={this.loadingControls}
          icon={_.get(this.getCurStep(), 'props.icon')}
          navTitle={_.get(this.getCurStep(), 'props.navTitle')}
          currentStep={this.getCurStep()}
          handleMovePrevious={this.handleMovePrevious}
          canMovePrevious={this.canMovePrevious}
          isLastStep={this.isLastStep}
          saveAndMoveToNext={this.saveAndMoveToNext}
          setSubmitWarning={() => this.context.setDialogBox({ show: true, type: 'submitWarning' })}
          btnLabels={[
            getLabel(localization, NavigationPaths.BACK, 'navigation'),
            getLabel(localization, NavigationPaths.NEXT, 'navigation'),
          ]}
        />
        <DialogWindow
          open={this.context.dialogBox.show && this.context.dialogBox.type === 'submitWarning'}
          onClose={() => this.context.setDialogBox({ show: false })}
          handleConfirm={async () => this.submitQuestionnaire()}
          title="Are you ready to submit the questionnaire?"
          text={
            'Your responses will be final and you will not be able to undo this action. \
            You will be able to discuss or edit information \
            with your genetics provider at your appointment.'
          }
        />
        <DialogWindow
          open={this.context.dialogBox.show && this.context.dialogBox.type === 'showSaveError'}
          onClose={() => this.nextAction()}
          handleConfirm={async () => this.saveAndMoveToNext()}
          title="Save Error"
          color="error"
          text={
            'Please check your internet connection or \
            try again later to ensure that your responses are captured correctly.'
          }
          primaryBtn="Try Again"
          secondaryBtn="Continue without saving"
        >
          {errorMessage &&
            Array.isArray(errorMessage) &&
            errorMessage.map((error, index) => (
              <CollapsibleError
                key={error.title + index.toString()}
                title={`${index + 1}: ${error.title}`}
              >
                {error.detail}
              </CollapsibleError>
            ))}
        </DialogWindow>
        <DialogWindow
          open={this.context.dialogBox.show && this.context.dialogBox.type === 'submitFailed'}
          onClose={() => this.context.setDialogBox({ show: false })}
          handleConfirm={async () => this.submitQuestionnaire()}
          title="Submission Error"
          color="error"
          text={
            'Please check your internet connection or \
            try again later to ensure that your responses are captured correctly.'
          }
          primaryBtn="Try Again"
        >
          {errorMessage ? <Typography variant="body2">{`\n${errorMessage}`}</Typography> : null}
        </DialogWindow>
        <DialogWindow
          open={this.context.dialogBox.show && this.context.dialogBox.type === 'missingFields'}
          onClose={() => this.context.setDialogBox({ show: false })}
          handleConfirm={async () => {
            await this.saveAndGoToStep({
              id: 'person-proband',
              label: 'Your Information',
              eventType: EventType.GO_BACK,
            })
            this.context.setDialogBox({ show: false })
            this.loadingControls('dialog', false)
          }}
          title="Missing required fields"
          color="error"
          text={errorMessage}
          primaryBtn="Go to missing fields"
        />
      </Box>
    )
  }

  scrollTop = () => {
    this.scrollBody.current.scrollTop = 0
  }

  canMovePrevious = () => {
    return this.getCurStepIndex(this.getCurStep()) > 0
  }

  isLastStep = () => {
    return this.getCurStepIndex(this.getCurStep()) === this.getSteps().length - 1
  }

  handleMovePrevious = () => {
    this.scrollTop()

    return this.moveSteps(-1, this.getCurStepIndex(this.getCurStep()), EventType.PREVIOUS_STEP)
  }

  handleMoveNext = (curStepId) => {
    const stepIndex = curStepId
      ? this.getCurStepIndex(this.getCurStep(curStepId))
      : this.getCurStepIndex(this.getCurStep())

    this.scrollTop()

    return this.moveSteps(1, stepIndex, EventType.SAVE_AND_CONTINUE)
  }

  loadingControls = (type, value) => {
    this.context.setLoading((prevState) => ({
      ...prevState,
      [type]: value,
    }))
  }

  saveAndMoveToNext = async () => {
    const { sendStateData } = this.props
    this.loadingControls('save', true)

    const { payload: { errors = [], error = '' } = {} } = (await sendStateData()) || {}

    if (errors.length || error) {
      this.context.setDialogBox({
        type: 'showSaveError',
        node: null,
        action: this.handleMoveNext,
        show: true,
        errorMessage: !errors.length ? error : errors,
      })
    } else {
      await this.handleMoveNext()
    }

    this.scrollTop()
  }

  // [Issue from the original code]: When keeping clicking `Try Again`,
  // it generates an out of bound error.
  // TODO: need to show DialogBox with different logic.
  // It should not have `Try Again` and `Without processing saving`
  saveAndGoToStep = async (node) => {
    if (node.type === 'folder') {
      if (node.id === this.state.expandedNodeId) {
        this.setState({
          expandedNodeId: false,
        })
      } else {
        this.setState({
          expandedNodeId: node.id,
        })
      }
    } else if (node.id !== this.state.selectedNavId) {
      this.loadingControls('save', true)
      const { payload: { errors = [] } = {}, error = '' } = (await this.props.sendStateData()) || {}

      if (errors.length || error) {
        this.context.setDialogBox({
          type: 'showSaveError',
          node,
          action: this.setCurStep,
          show: true,
          errorMessage: !errors.length ? error : errors,
        })
      } else {
        await this.setCurStep(node)
      }
    }
  }

  nextAction = () => {
    this.context.dialogBox.action(this.context.dialogBox.node)
    this.context.setDialogBox({ show: false })
    this.scrollTop()
  }

  // check if all required fields are completed
  missingRequiredFields() {
    const { persons, probandId } = this.props
    const p = persons[probandId]
    const missingFields = []

    // Full name
    if (_.isEmpty(p.name?.firstName)) missingFields.push('First Name')
    if (_.isEmpty(p.name?.lastName)) missingFields.push('Last Name')
    // Sex field
    if (_.isEmpty(p.sex) || p.sex === 'U') missingFields.push('Sex assigned at birth')

    return _.isEmpty(missingFields) ? null : missingFields
  }

  submitQuestionnaire = async () => {
    const { setAuthState, setDialogBox, addToast } = this.context

    const fields = this.missingRequiredFields()

    if (fields) {
      this.loadingControls('dialog', false)
      this.context.setDialogBox({
        show: true,
        type: 'missingFields',
        errorMessage: 'You must fill out all required fields: ' + fields.join(', '),
      })

      return
    }

    this.loadingControls('submit', true)

    const { payload: { errors = [], error = '' } = {} } = (await this.props.sendStateData()) || {}

    if (errors.length || error) {
      // [Issue from the original code]
      // TODO: need to show DialogBox with different logic.
      // It should not have `Try Again` and `Without processing saving`
      return
    }

    // Must use `thunk` return, not from `fetch` return.
    const {
      meta: { requestStatus = '' },
    } =
      (await this.props.submitStateData({
        sendMetricsData: () =>
          this.props.sendMetricsData({
            newDestinationValue: 'ThankYou',
            eventType: EventType.SUBMIT,
          }),
      })) || {}

    if (requestStatus !== 'fufilled') {
      this.context.setDialogBox({
        show: true,
        type: 'submitFailed',
        errorMessage: `Error: The application encountered an unexpected error`,
      })
    } else {
      setDialogBox(() => ({ show: false }))

      setAuthState(() => ({
        isAuthenticated: false,
        loggingIn: true,
      }))

      addToast('success', 'info', 'Successfully Submitted')
    }
    this.loadingControls('dialog', false)
  }

  renderSidebarNavItem = (step) => {
    if (Array.isArray(step)) {
      const childNodes = step.map((childNode, i) => {
        if (i !== 0) {
          return {
            key: childNode.key,
            id: childNode.props.navId,
            label: childNode.props.navTitle,
            disabled: !!this.context.loading?.save,
            isSelected: childNode === this.getCurStep(),
          }
        } else {
          return childNode
        }
      })

      const folderInfo = {
        key: childNodes[0].id,
        id: childNodes[0].id,
        label: childNodes[0].label,
        disabled: !!this.context.loading?.save,
        isSelected: false,
        isExpanded: childNodes[0].id === this.getExpandedFolder(),
        type: 'folder',
      }

      // set the first item in the array as the folder step
      childNodes.forEach((childNode) => {
        childNode.containingFolder = childNodes[0].id
      })

      // remove folder step from child node list
      childNodes.shift()
      folderInfo.childNodes = childNodes

      return folderInfo
    }

    return {
      key: step.key,
      id: step.props.navId,
      label: step.props.navTitle,
      disabled: !!this.context.loading?.save,
      isSelected: step === this.getCurStep(),
    }
  }

  getExpandedFolder = () => {
    if (this.state.expandedNodeId) {
      const steps = _.flattenDeep(this.getStepsAndFolders()).filter((step) => {
        return step.type === 'folder' && step.id === this.state.expandedNodeId
      })

      return steps[0].id
    } else {
      return false
    }
  }

  getStepsAndFolders = () => {
    return _.flatten(this.props.children).filter((step) => step && step.key !== 'controls')
  }

  getSteps = () => {
    return _.flattenDeep(this.props.children).filter(
      (step) => step && step.type !== 'folder' && step.key !== 'controls',
    )
  }

  /**
   * Uses this.state.selectedNavId to return the currently selected step component instance
   * from this.props.children.
   *
   * this.state.selectedNavId is expected to hold a string corresponding
   * to the navId value of the instance.
   */
  getCurStep = (selectedNavId = this.state.selectedNavId) => {
    if (selectedNavId) {
      const steps = _.flattenDeep(this.getSteps()).filter((step) => {
        return step.type !== 'folder' && step.props[Navigation.NAV_ID_PROPNAME] === selectedNavId
      })

      return steps[0]
    } else {
      return this.getSteps()[0]
    }
  }

  /**
   * Gets the index of the current step by looking through all steps.
   */
  getCurStepIndex = (curStep) => {
    return _.findIndex(this.getSteps(), curStep)
  }

  /**
   * Moves the current navigation step by the requested number of steps.
   *
   * @param {number} numberSteps a negative
   * or positive integer representing the number of steps to move.
   */
  moveSteps = async (numberSteps, currentStepIndex, eventType) => {
    const newStepIndex = currentStepIndex + numberSteps

    if (newStepIndex < 0 || newStepIndex >= this.getSteps().length) {
      throw new Error('New step is out of bounds')
    }

    const folderId = this.getContainingFolder(newStepIndex) || this.state.expandedNodeId

    this.setState({
      selectedNavId: this.getSteps()[newStepIndex].props[Navigation.NAV_ID_PROPNAME],
      expandedNodeId: folderId,
    })
    await this.updateMetricPages(eventType, '', newStepIndex)
  }

  async updateMetricPages(eventType, pageLabel = '', newStepIndex = undefined) {
    await this.props.sendMetricsData({
      newDestinationValue: pageLabel || this.getSteps()[newStepIndex].props?.navTitle,
      eventType,
    })
  }

  /**
   * Sets the current step based on navigation ID of the given node.
   *
   * @param {string} node The node to grab the navId from
   */
  setCurStep = async (node) => {
    if (node.type !== 'folder') {
      this.setState({
        selectedNavId: node.id,
        burgerMenuOpened: false,
      })
      this.scrollTop()
    }

    if (node.label && node.eventType) {
      await this.updateMetricPages(node.eventType, node.label, undefined).catch(() => {})
    }
  }

  getContainingFolder = (stepIndex) => {
    const compareStep = (step, currentStep) => {
      if (Array.isArray(step)) {
        return step.map((step) => compareStep(step, currentStep))
      } else {
        return step === currentStep
      }
    }

    if (this.getStepsAndFolders().indexOf(this.getSteps()[stepIndex]) === -1) {
      let index = null

      const folders = this.getStepsAndFolders()
        .filter((step) => Array.isArray(step))
        .map((step) => step.map((childNode) => childNode))

      folders
        .map((step) => compareStep(step, this.getSteps()[stepIndex]))
        .forEach((isContainingStep, i) => {
          if (isContainingStep.includes(true)) {
            index = i
          }
        })

      return folders[index][0].id
    } else {
      return false
    }
  }
}

Navigation.NAV_ID_PROPNAME = 'navId'

const mapStateToProps = ({ localization, questionnaire }) => ({
  localization,
  persons: questionnaire.persons,
  probandId: questionnaire.probandId,
})

export default connect(mapStateToProps, { sendStateData, submitStateData, sendMetricsData })(
  Navigation,
)
