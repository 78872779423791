import _ from 'lodash'
import React from 'react'
import {
  AdditionalInformation,
  Conclusion,
  Instructions,
  Introduction,
  Parents,
  RelativeLinkingQuestions,
} from '../steps'
import { Consent } from '../steps/Consent'
import { HeartHealthHistory } from '../steps/HeartHealthHistory'
import Person from '../steps/Person'
import { RelativeDeathDetails } from '../steps/RelativeDeathDetails.jsx'
import cancerRelativeQuestions from '../utils/cancerRelativeQuestions'
import conditionRelativeQuestions from '../utils/conditionRelativeQuestions'
import { renderSubSection } from '../utils/helpers'
import { PersonFieldPaths, PersonProperties } from '../utils/QuestionnaireStateManager'

function questionnaireMap({
  questionnaire,
  configuration,
  renderProbandFamilySteps,
  renderMotherSteps,
  renderFatherSteps,
  renderFolder,
}) {
  const { sectionConfig } = configuration
  const { persons, probandId } = questionnaire
  const proband = persons[probandId]
  const heartHealthConfig = sectionConfig['heart-health-section']?.subSectionConfig || {}
  const heartHealthSubQuestions = (key) => heartHealthConfig[_.kebabCase(key)]?.questions

  const renderMap = {
    introduction: (
      <Introduction key="introduction" navId="introduction" navTitle="Introduction" icon="notes" />
    ),
    instructions: (
      <Instructions key="instructions" navId="instructions" navTitle="Instructions" icon="list" />
    ),
    parents: <Parents key="parents" navId="parents" navTitle={'Parents'} icon="people" />,
    // code change request: should use camel
    yourInformation: (
      <Person
        key="person-proband"
        navId="person-proband"
        navTitle="Your Information"
        icon="person"
        targetPerson="proband"
        type="proband"
      />
    ),
    yourFamily: renderProbandFamilySteps({ proband }),
    mothersFamily: renderMotherSteps({ questionnaire, persons, proband }),
    fathersFamily: renderFatherSteps({ questionnaire, persons, proband }),
    heartHealthSection: renderFolder(
      'heartHealthSection',
      'Heart Health History',
      renderSubSection({
        renderMap: {
          probandHeartHealth: (
            <HeartHealthHistory
              key="heartHealth"
              navId="heartHealth"
              navTitle="Your Heart Health"
              icon="heart"
              config={heartHealthSubQuestions('probandHeartHealth')}
            />
          ),
          relativesHeartConditions: (
            <RelativeLinkingQuestions
              key="heartConditions"
              navId="heartConditions"
              navTitle="Heart Disease"
              icon="heart"
              label="Do any of your relatives have a known heart disease or heart condition?"
            />
          ),
          relativesCardiacDevice: (
            <RelativeLinkingQuestions
              key="cardiacDevice"
              navId="cardiacDevice"
              navTitle="Cardiac Device"
              icon="heart"
              label="Have any of your family members required a
              pacemaker or implantable defibrillator (ICD)?"
            />
          ),
          relativesEpilepsy: (
            <RelativeLinkingQuestions
              key="epilepsy"
              navId="epilepsy"
              navTitle="Epilepsy or Seizures"
              icon="people"
              label="Do any of your relatives have epilepsy or seizures?"
            />
          ),
          relativesHearingLoss: (
            <RelativeLinkingQuestions
              key="hearingLoss"
              navId="hearingLoss"
              navTitle="Hearing Loss"
              icon="people"
              label="Do any of your relatives have hearing loss?"
            />
          ),
          relativeDeathDetails: (
            <RelativeDeathDetails
              key="relativeDeathDetails"
              navId="relativeDeathDetails"
              navTitle="Relative Death Details"
              icon="people"
              config={heartHealthSubQuestions('relativeDeathDetails')}
            />
          ),
        },
        config: heartHealthConfig,
      }),
    ),
    cancerRelatedQuestion: renderFolder(
      'cancerRelativeQuestions',
      'Cancer Related Questions',
      relativeQuestionReducer(cancerRelativeQuestions, 'cancerRelatedQuestion'),
    ),
    medicalConditions: renderFolder(
      'conditionRelativeQuestions',
      'Medical Conditions',
      relativeQuestionReducer(conditionRelativeQuestions, 'medicalConditions'),
    ),
    geneticTesting: (
      <RelativeLinkingQuestions
        key="genetic-testing"
        navId={PersonFieldPaths.GENETIC_TESTING}
        icon="people"
        navTitle="Genetic Testing"
        propertyPath={PersonFieldPaths.HAS_RELATIVE_SEEN_ELSEWHERE}
        propertyType={PersonFieldPaths.GENETIC_TESTING}
        personProperty={PersonFieldPaths.GENETIC_TESTING_PERFORMED}
        otherQuestions
      />
    ),
    genderIdentity: (
      <RelativeLinkingQuestions
        key="gender-identity"
        navId={PersonFieldPaths.GENDER_IDENTITY}
        icon="people"
        navTitle="Gender Identity"
        propertyPath={PersonFieldPaths.GENDER_IDENTITY}
        propertyType={PersonFieldPaths.PROPERTIES}
        personProperty={PersonProperties.HAS_DIFFERENT_GENDER_IDENTITY}
        otherQuestions
      />
    ),
    additionalInfo: (
      <AdditionalInformation
        key="additional-information"
        navId="additional-information"
        navTitle={'Additional Information'}
        icon="notes"
      />
    ),
    consent: <Consent key="consent" navId="consent" navTitle={'Consent'} icon="notes" />,
    conclusion: (
      <Conclusion key="conclusion" navId="conclusion" navTitle={'Conclusion'} icon="completed" />
    ),
  }

  const steps = []

  for (const key in renderMap) {
    if (sectionConfig[_.kebabCase(key)]?.enable) {
      steps.push(renderMap[key])
    }
  }

  return steps

  function relativeQuestionReducer(folderQuestions, configId) {
    const subSections = sectionConfig[_.kebabCase(configId)]?.subSectionConfig || {}
    const relativeLinkQuestions = []
    folderQuestions.forEach((question) => {
      if (
        Object.keys(subSections).find((key) => key === question.configId) &&
        subSections[question.configId]?.enable
      ) {
        relativeLinkQuestions.push(
          <RelativeLinkingQuestions
            key={question.id}
            navId={question.id}
            configId={question.configId}
            icon="people"
            navTitle={question.title}
            propertyPath={question.propertyPath}
            propertyType={question.propertyType}
            personProperty={question.personProperty}
            otherQuestions={question.otherQuestions}
          />,
        )
      }
    })

    return !relativeLinkQuestions.length ? null : relativeLinkQuestions
  }
}

export default questionnaireMap
