import { Container } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { getLabel, renderContent, subQuestions } from '../../utils/helpers'
import { PersonFieldPaths } from '../../utils/QuestionnaireStateManager'
import Chooser from '../../widgets/Chooser'
import DateOfBirth from '../../widgets/DateOfBirth'
import FullName from '../../widgets/FullName'
import HalfSiblingParent from '../../widgets/HalfSiblingParent'
import LifeStatus from '../../widgets/LifeStatus'
import SexAssignedAtBirth from '../../widgets/SexAssignedAtBirth'

const SiblingQuestions = ({ personId, isHalfSibling, resolvePersonId, isProband, config }) => {
  const { setTwinRelationship, setPersonPropertiesValue } = useActions()

  const { probandId, persons, localization } = useSelector(
    ({ questionnaire: { probandId, persons }, localization }) => ({
      probandId,
      persons,
      localization,
    }),
  )
  const { sex: probandSex = '', properties = [] } = persons[probandId]

  const renderMap = {
    sharedParent: isHalfSibling ? (
      <HalfSiblingParent key="sp" referencePersonId={personId} resolvePersonId={resolvePersonId} />
    ) : null,
    name: (
      <FullName
        key="fn"
        id={personId}
        localization={localization}
        config={subQuestions({ config, key: 'name' })}
      />
    ),
    sexAtBirth: <SexAssignedAtBirth key="sab" id={personId} />,
    dateOfBirth: <DateOfBirth key="dob" id={personId} />,
    lifeStatus: (
      <LifeStatus
        key="ls"
        personId={personId}
        config={subQuestions({ config, key: 'lifeStatus' })}
      />
    ),
  }
  const content = []

  renderContent({ renderMap, config, content })

  return (
    <Container key={'sibling-' + personId}>
      {content}
      {isProband &&
        doesProbandHaveATwin() &&
        !isHalfSibling &&
        renderContent({
          renderMap: {
            name: renderLinkTwins(),
          },
          config,
        })}
    </Container>
  )

  /**
   * Selector for indicating twin of proband
   * @param {String} personId - Sibling id in question
   * @returns {React.ReactElement} Twin property selector
   **/
  function renderLinkTwins() {
    const { sex = '', properties = [] } = persons[personId] || {}

    return (
      <Chooser
        key="twin"
        label={getLabel(localization, PersonFieldPaths.IS_TWIN, 'person')}
        onChange={(value) => {
          // isFunction should be implemented !!!!!!!!!!
          setTwinRelationship({
            siblingId: personId,
            probandId,
            relPath: value,
          })

          setPersonPropertiesValue({
            id: personId,
            type: 'twin',
            value,
          })
        }}
        selectedValue={properties.find(({ type }) => type === 'twin')?.value}
        orderSchema={['monozygoticTwin', 'dizygoticTwin', 'N']}
        path={PersonFieldPaths.IS_TWIN}
        disabled={[probandSex !== sex]}
      />
    )
  }
  /**
   * Returns if proband has checked twin status
   * @returns {Boolean}
   */
  function doesProbandHaveATwin() {
    const { isPresent = '' } = properties.find(({ type }) => type === 'twin') || {}

    return isPresent === 'Y'
  }
}

export default SiblingQuestions
