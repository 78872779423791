import { Box, Checkbox, FormControlLabel } from '@mui/material'
import _ from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { getLabel } from '../../utils/helpers'
import { PersonFieldPaths as paths } from '../../utils/QuestionnaireStateManager'
import CheckBoxList from '../../widgets/CheckBoxList'
import Chooser from '../../widgets/Chooser'
import FollowUpQuestions from '../../widgets/FollowUpQuestions'
import FormLabel from '../../widgets/FormLabel'

const Surgeries = () => {
  const { removePersonSurgeries, setPersonSurgeries } = useActions()

  const { probandId, persons, localization } = useSelector(({ questionnaire, localization }) => ({
    localization,
    persons: questionnaire.persons,
    probandId: questionnaire.probandId,
  }))

  const surgeriesList = [
    {
      id: 'lumpectomy',
      name: getLabel(localization, paths.LUMPECTOMY_LABEL, 'patient'),
      tissue: 'breasts',
    },
    {
      id: 'mastectomy',
      name: getLabel(localization, paths.MASTECTOMY_LABEL, 'patient'),
      tissue: 'breasts',
    },
    {
      id: 'hysterectomy',
      name: getLabel(localization, paths.HYSTERECTOMY_LABEL, 'patient'),
      tissue: 'uterus',
    },
    {
      id: 'tubalLigation',
      name: getLabel(localization, paths.TUBAL_LIGATION_LABEL, 'patient'),
      tissue: 'tubes',
    },
    {
      id: 'oophorectomy',
      name: getLabel(localization, paths.OOPHORECTOMY_LABEL, 'patient'),
      tissue: 'ovaries',
    },
    {
      id: 'salpingectomy',
      name: getLabel(localization, paths.SALPINGECTOMY_LABEL, 'patient'),
      tissue: 'fallopian tubes',
    },
  ]

  const { surgeries: _surgeries = [] } = persons[probandId]

  const handleSurgeryChange = (surgery) => () => {
    const surgeryIndex = _.findIndex(_surgeries, { type: surgery.id })

    if (surgeryIndex >= 0) {
      removePersonSurgeries({ id: probandId, type: surgery.id })
    } else {
      setPersonSurgeries({
        id: probandId,
        surgeryType: surgery.id,
        type: 'type',
        value: surgery.id,
      })
    }
  }

  const surgeriesWithSide = ['lumpectomy', 'mastectomy', 'salpingectomy', 'oophorectomy']

  return (
    <FormLabel label={getLabel(localization, paths.SURGERY_LABEL, 'patient')}>
      <CheckBoxList>
        {surgeriesList.map((surgery) => {
          const isChecked = _.includes(_.map(_surgeries, 'type'), surgery.id)
          const selectedValue = _.find(_surgeries, { type: surgery.id })

          return (
            <Box key={surgery.id}>
              <FormControlLabel
                label={surgery.name}
                control={<Checkbox checked={isChecked} onChange={handleSurgeryChange(surgery)} />}
              />
              {isChecked && _.includes(surgeriesWithSide, surgery.id) && (
                <FollowUpQuestions>
                  <Chooser
                    label={`One or both ${surgery.tissue}?`}
                    onChange={(value) => {
                      if (value !== selectedValue.lateralityType) {
                        setPersonSurgeries({
                          id: probandId,
                          surgeryType: surgery.id,
                          type: 'lateralityType',
                          value,
                        })
                      }
                    }}
                    selectedValue={selectedValue.lateralityType}
                    orderSchema={['left', 'right', 'bothSides']}
                    patient={null}
                    path="laterality"
                  />
                </FollowUpQuestions>
              )}
            </Box>
          )
        })}
      </CheckBoxList>
    </FormLabel>
  )
}

export default Surgeries
