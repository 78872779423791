import { Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import RemoveLoadingHook from '../../hooks/RemoveLoadingHook'
import { renderContent, subQuestions } from '../../utils/helpers'
import InstructionList from './InstructionList'
import Respondent from './Respondent'

function Instructions() {
  const { config = [], paragraphs = [] } = useSelector(({ configuration: { sectionConfig } }) => ({
    paragraphs: sectionConfig.instructions?.paragraphs,
    config: sectionConfig.instructions?.questions,
  }))
  const content = []

  RemoveLoadingHook()

  paragraphs.forEach((textItem, index) => {
    // create instruction for every 2 items
    if (index % 2 === 1) {
      content.push(
        <InstructionList
          key={paragraphs[index - 1]}
          title={paragraphs[index - 1]}
          description={textItem}
        />,
      )
    }
  })
  renderContent({
    renderMap: {
      behalf: (
        <div key="respondent">
          <Typography key="respondent-title" variant="h3" pb={0.5}>
            Respondent Info
          </Typography>
          <Respondent config={subQuestions({ config, key: 'behalf' })} />
        </div>
      ),
    },
    config,
    content,
  })

  return <>{content}</>
}

export { Instructions }
