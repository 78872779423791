import { Main } from './Main'
import { navigateToErrorScreen } from './utils/errors'

export class Setup extends Main {
  static baseUrl = this.getRoot()

  static async fetchData(url, fetchType) {
    try {
      const response = await (
        await fetch(url, {
          method: 'GET',
        })
      ).json()

      const { errors, error, message } = response || {}

      if (errors || error) {
        throw new Error(message || error || errors?.[0]?.detail || '*')
      }

      return response
    } catch (err) {
      navigateToErrorScreen({
        fetchType,
        status: err.message,
      })
    }
  }

  static async getLocalization() {
    const url = `${this.baseUrl}/config/localization`

    return this.fetchData(url, 'getLocalization')
  }

  static async getConfiguration(questionnaireType) {
    const url = `${this.baseUrl}/config/questionnaire/${questionnaireType}`

    return this.fetchData(url, 'getConfiguration')
  }

  static async getThankYou() {
    const url = `${this.baseUrl}/config/page/thank-you`

    return this.fetchData(url, 'getThankYou')
  }
}
