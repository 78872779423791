import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { getLabel } from '../../utils/helpers'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'

const AdditionalNote = ({ type }) => {
  const {
    additionalInformation = '',
    additionalHealthProblems = '',
    relevantInfo = '',
    localization,
  } = useSelector(({ questionnaire, localization }) => ({
    additionalInformation: questionnaire.notes,
    additionalHealthProblems: questionnaire.additionalHealthProblems,
    relevantInfo: questionnaire.relevantInfo,
    localization,
  }))
  const { setAdditionalNote, setAdditionalHealthProblems, setAdditionalRelevantInfo } = useActions()

  const valueMap = { additionalInformation, additionalHealthProblems, relevantInfo }
  const functionMap = {
    additionalInformation: handleNotesChange,
    additionalHealthProblems: handleHealthProblemsChange,
    relevantInfo: handleRelevantInfoChange,
  }

  return (
    <TextFieldQuestion
      fullWidth
      label={getLabel(localization, type)}
      value={valueMap[type]}
      handleChange={functionMap[type]}
    />
  )

  function handleNotesChange(value) {
    setAdditionalNote({ value })
  }
  function handleHealthProblemsChange(value) {
    setAdditionalHealthProblems({ value })
  }
  function handleRelevantInfoChange(value) {
    setAdditionalRelevantInfo({ value })
  }
}

export default AdditionalNote
