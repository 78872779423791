import React from 'react'
import FormLabel from '../../widgets/FormLabel'
import IntegerDetails from '../../widgets/IntegerDetails'

const MenstrualPeriod = ({ label, ...props }) => {
  return (
    <FormLabel label={label}>
      <IntegerDetails
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
            max: 150,
          },
        }}
        {...props}
      />
    </FormLabel>
  )
}

export default MenstrualPeriod
