import { FormGroup } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../hooks/useActions'
import FamilyHelper from '../utils/FamilyHelper'
import { capitalize, getLabel, getParentStrFromSex } from '../utils/helpers'
import { PersonFieldPaths } from '../utils/QuestionnaireStateManager.js'
import ButtonGroup from './ButtonGroup'

function HalfSiblingParent({ referencePersonId, resolvePersonId }) {
  /**
   * referenceId: the created person (sibling) Id in this `PersonSiblingStep`
   * resolvePersonId: Mother, Father, etc (It is not available in Proband)
   */
  const { setPersonPropertiesValue, setHalfSiblingSharedParentType } = useActions()

  const { localization, questionnaire } = useSelector(({ questionnaire, localization }) => ({
    localization,
    questionnaire,
  }))

  const onChange = (value) => {
    setPersonPropertiesValue({
      id: referencePersonId,
      type: 'sharedParent',
      value: capitalize(getParentStrFromSex(value)),
    })

    setHalfSiblingSharedParentType({
      personId: referencePersonId,
      designatedPersonId: resolvePersonId,
      sharedParentSex: value,
    })
  }

  const selectedValue = new FamilyHelper(questionnaire).getHalfSiblingSharedParentType(
    referencePersonId,
    referencePersonId,
  )

  return (
    <FormGroup>
      <ButtonGroup
        onChange={onChange}
        selectedValue={selectedValue}
        options={[
          ['F', getLabel(localization, PersonFieldPaths.SHARED_PARENT_MOTHER, 'person')],
          ['M', getLabel(localization, PersonFieldPaths.SHARED_PARENT_FATHER, 'person')],
        ]}
      />
    </FormGroup>
  )
}

export default HalfSiblingParent
