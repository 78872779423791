import { Box, Checkbox, FormControlLabel } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { getLabel, renderContent, subQuestions } from '../../utils/helpers'
import { PersonConditions, PersonFieldPaths as paths } from '../../utils/QuestionnaireStateManager'
import CheckBoxList from '../../widgets/CheckBoxList'
import FollowUpQuestions from '../../widgets/FollowUpQuestions'
import FormLabel from '../../widgets/FormLabel'
import TextFieldQuestion from '../../widgets/TextFieldQuestion'

const conditionsMap = {
  pancreatitis: { conditionId: PersonConditions.PANCREATITIS, labelPath: paths.PANCREATITIS },
  tumour: { conditionId: PersonConditions.TUMOUR, labelPath: paths.TUMOUR },
  pituitary: { conditionId: PersonConditions.PITUITARY, labelPath: paths.PITUITARY },
  moles: { conditionId: PersonConditions.MOLE_NEVI, labelPath: paths.MOLE_NEVI },
  polyps: { conditionId: PersonConditions.POLYPS_REMOVED, labelPath: paths.POLYPS_REMOVED },
  endometriosis: { conditionId: PersonConditions.ENDOMETRIOSIS, labelPath: paths.ENDOMETRIOSIS },
  bloodClot: { conditionId: PersonConditions.BLOOD_CLOT, labelPath: paths.BLOOD_CLOT },
}

const ConditionSwitch = ({ conditionId, id, label, isChecked }) => {
  const { setPersonConditions, removePersonConditions } = useActions()

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isChecked}
          onChange={(e) => {
            e.currentTarget.checked
              ? setPersonConditions({ id, conditionId })
              : removePersonConditions({ id, conditionId })
          }}
        />
      }
      label={label}
    />
  )
}

const Conditions = ({ config }) => {
  const { localization, probandId, persons, sex } = useSelector(
    ({ questionnaire: { probandId, persons }, localization }) => ({
      localization,
      probandId,
      persons,
      sex: persons[probandId].sex,
    }),
  )
  const { setPersonConditionNotes } = useActions()
  const { conditions = [] } = persons[probandId]

  const content = []
  renderContent({ renderMap: conditionsMap, config, content })

  return (
    <FormLabel label="Have you had any of the following conditions?">
      <CheckBoxList>
        {content.map(({ conditionId, labelPath }) => {
          const condition = conditions.find(({ id }) => id === conditionId)
          const isChecked = condition?.isPresent === 'Y'
          const subConfig = subQuestions({ config, key: conditionId })

          // endometriosis only for female
          return conditionId === PersonConditions.ENDOMETRIOSIS && sex !== 'F' ? null : (
            <Box key={'condition-' + conditionId}>
              <ConditionSwitch
                conditionId={conditionId}
                labelPath={labelPath}
                label={getLabel(localization, labelPath, paths.CONDITIONS)}
                isChecked={isChecked}
                sex={sex}
                id={probandId}
              />
              {isChecked &&
                renderContent({
                  renderMap: {
                    conditionsDescribe: (
                      <FollowUpQuestions key={'description-' + conditionId}>
                        <TextFieldQuestion
                          label="Please describe"
                          value={condition?.notes || ''}
                          handleChange={(value) =>
                            setPersonConditionNotes({ id: probandId, conditionId, value })
                          }
                        />
                      </FollowUpQuestions>
                    ),
                  },
                  config: subConfig,
                })}
            </Box>
          )
        })}
      </CheckBoxList>
    </FormLabel>
  )
}

export default Conditions
