import { FormControl, MenuItem, Select, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import * as CancerTypes from '../utils/CancerTypes.js'
import { renderContent } from '../utils/helpers.js'
import ConditionalSection from '../widgets/ConditionalSection'
import FormLabel from './FormLabel.jsx'
import IntegerDetails from './IntegerDetails.jsx'

export default function SpecificCancerQuestions({
  category,
  cancerType,
  setCancerType,
  setTreatmentLocation,
  treatmentLocation,
  setPatientAddress,
  patientAddress,
  qualifiers,
  setQualifiers,
  handleQualifiersChange,
  secondBreastCancer,
  setSecondBreastCancer,
  getCancerTypes,
  isProband,
  config,
}) {
  useEffect(() => {
    // Should only work for `Breast` or `Breast Cancer`.
    setQualifiers([])
    setSecondBreastCancer()
  }, [])

  const renderCancerTypes = () => {
    const cancerTypes = getCancerTypes(category.label)

    return cancerTypes ? (
      <FormLabel label="Select Type" key="cancerType">
        <FormControl fullWidth>
          <Select
            value={cancerType?.id ?? ''}
            onChange={({ target: { value: newCancerTypeId } }) => {
              const _cancerType = cancerTypes.find(({ id }) => id === newCancerTypeId)

              if (newCancerTypeId !== cancerType.id) {
                setCancerType(_cancerType)
              }

              if (cancerType.id && newCancerTypeId !== cancerType.id) {
                setQualifiers([])
                setTreatmentLocation('')
                setPatientAddress('')
              }
            }}
          >
            {cancerTypes.map(({ id, label }) => (
              <MenuItem key={id} value={id}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FormLabel>
    ) : (
      renderOtherType()
    )
  }

  const renderOtherType = () => {
    return category.label === 'Other' ? (
      <FormLabel label="Please Describe" key="cancerOtherType">
        <TextField
          fullWidth
          inputProps={{ maxLength: 150 }}
          onChange={({ target: { value: newCancerTypeId } }) => {
            setCancerType({ id: 'other', label: newCancerTypeId })
          }}
          value={cancerType?.label ?? ''}
        />
      </FormLabel>
    ) : null
  }

  const renderAgeAtDiagnosis = () => {
    return category.label ? (
      <FormLabel label="Age at diagnosis?" key="cancerAge">
        <IntegerDetails
          value={qualifiers[0]?.numericAgeAtDiagnosis ?? ''}
          onChange={(value) => {
            handleQualifiersChange(0, {
              numericAgeAtDiagnosis: value,
            })
          }}
        />
      </FormLabel>
    ) : null
  }

  const renderTreatmentLocation = () => {
    if ('label' in category) {
      return (
        <FormLabel label="Treating hospital or location" key="cancerHospital">
          <TextField
            fullWidth
            inputProps={{ maxLength: 150 }}
            onChange={({ target: { value } }) => {
              setTreatmentLocation(value)
            }}
            value={treatmentLocation}
          />
        </FormLabel>
      )
    }
  }

  const renderPatientAddress = () => {
    if ('label' in category) {
      return (
        <FormLabel label="Address when treated? (enter town/city if unknown)" key="cancerAddress">
          <TextField
            fullWidth
            inputProps={{ maxLength: 150 }}
            onChange={({ target: { value } }) => {
              setPatientAddress(value)
            }}
            value={patientAddress}
          />
        </FormLabel>
      )
    }
  }

  const renderBreastSideSelect = () => {
    if (category.label === 'Breast') {
      return [
        <FormLabel label="Which side of the body?" key="cancerSide">
          <Select
            value={qualifiers[0]?.laterality || ''}
            onChange={({ target: { value } }) => {
              handleQualifiersChange(0, { laterality: value })
            }}
          >
            {CancerTypes.BREAST_LATERALITY.map((i) => (
              <MenuItem key={i.id} value={i.id}>
                {i.label}
              </MenuItem>
            ))}
          </Select>
        </FormLabel>,
        renderSecondOccurrence(),
      ]
    }
  }

  const renderSecondOccurrence = () => {
    if (cancerType && cancerType.label === 'Breast cancer') {
      return (
        <ConditionalSection
          key="cancerSecondOccurrence"
          label={
            isProband
              ? 'Have you had breast cancer more than once?'
              : 'Has this person had breast cancer more than once?'
          }
          showWhenCondition="Y"
          conditionState={secondBreastCancer}
          onConditionStateChange={(e) => {
            setSecondBreastCancer(e)
          }}
          orderSchema={['Y', 'N', 'U']}
          cleanUpChildQuestions={() => {
            if (qualifiers.length === 2) {
              if (qualifiers[0]) {
                setQualifiers([qualifiers[0]])
              } else {
                setQualifiers([])
              }
            }
          }}
        >
          <FormLabel label="Which side of the body?">
            <Select
              value={qualifiers[1]?.laterality || ''}
              onChange={({ target: { value } }) => {
                handleQualifiersChange(1, { laterality: value })
              }}
            >
              {CancerTypes.BREAST_LATERALITY.map((i) => (
                <MenuItem key={i.id} value={i.id}>
                  {i.label}
                </MenuItem>
              ))}
            </Select>
          </FormLabel>
          <FormLabel label="Age at second diagnosis?">
            <IntegerDetails
              value={qualifiers[1]?.numericAgeAtDiagnosis ?? ''}
              onChange={(value) => {
                handleQualifiersChange(1, {
                  numericAgeAtDiagnosis: value,
                })
              }}
            />
          </FormLabel>
        </ConditionalSection>
      )
    }
  }

  return renderContent({
    renderMap: {
      cancerType: renderCancerTypes(),
      cancerDiagnoseAge: renderAgeAtDiagnosis(),
      cancerHospitalLocation: renderTreatmentLocation(),
      cancerTreatmentAddress: renderPatientAddress(),
      cancerSide: renderBreastSideSelect(),
    },
    config,
  })
}
