import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Card, CardContent, CardHeader, Collapse, IconButton } from '@mui/material'
import React, { useState } from 'react'

export default function InternalCard({
  fullName,
  relationship,
  removePersonEntry,
  children,
  medicalConditions,
  opened,
}) {
  const [entryRendererOpen, toggleEntryRenderer] = useState(opened)

  return (
    <Card elevation={2} sx={{ borderRadius: 2 }}>
      <CardHeader
        sx={{ cursor: 'pointer' }}
        avatar={<AccountCircleIcon fontSize="large" color="primary" />}
        title={fullName}
        subheader={relationship}
        action={
          <>
            {entryRendererOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            <IconButton
              color="error"
              onClick={(e) => {
                e.stopPropagation()
                removePersonEntry()
              }}
            >
              <DeleteForeverIcon />
            </IconButton>
          </>
        }
        onClick={() => toggleEntryRenderer(!entryRendererOpen)}
      />
      <Collapse in={entryRendererOpen}>
        <CardContent>{children}</CardContent>
      </Collapse>
    </Card>
  )
}
