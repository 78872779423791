import { TextField } from '@mui/material'
import React from 'react'
import { debounceTextInput } from '../utils/helpers'
import FormLabel from './FormLabel'

let time

const TextFieldQuestion = ({ label, value, handleChange, fullWidth = false }) => {
  return (
    <FormLabel label={label} fullWidth={fullWidth}>
      <TextField
        fullWidth
        multiline={true}
        inputProps={{ maxLength: 65000 }}
        onChange={({ target: { value } }) =>
          debounceTextInput({
            time,
            reduxAction: () => handleChange(value),
          })
        }
        value={value}
      />
    </FormLabel>
  )
}

export default TextFieldQuestion
