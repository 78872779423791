import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { getLabel } from '../../utils/helpers'
import { PersonFieldPaths, PersonConditions } from '../../utils/QuestionnaireStateManager'
import Chooser from '../../widgets/Chooser'

const Endometriosis = ({ id, patient }) => {
  const { setPersonConditions, setPersonConditionIsPresent } = useActions()
  const {
    conditions,
    sex = '',
    localization,
  } = useSelector(({ questionnaire: { persons }, localization }) => ({
    conditions: persons[id].conditions || [],
    sex: persons[id].sex,
    localization,
  }))
  const { isPresent } = conditions.find(({ id }) => id === PersonConditions.ENDOMETRIOSIS) || {}

  if (sex !== 'F') return null

  return (
    <Chooser
      label={getLabel(localization, PersonFieldPaths.ENDOMETRIOSIS_DIAGNOSED, patient)}
      orderSchema={['Y', 'N', 'U']}
      path="yesNoUnknown"
      selectedValue={isPresent}
      onChange={(value) => {
        if (value && !isPresent) {
          setPersonConditions({ id, conditionId: PersonConditions.ENDOMETRIOSIS })
        }
        setPersonConditionIsPresent({ id, conditionId: PersonConditions.ENDOMETRIOSIS, value })
      }}
    />
  )
}

export default Endometriosis
