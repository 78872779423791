import { MenuItem, Select } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { getLabel, renderContent } from '../../utils/helpers'
import { PersonFieldPaths as paths } from '../../utils/QuestionnaireStateManager'
import ConditionalSection from '../../widgets/ConditionalSection'
import FormLabel from '../../widgets/FormLabel'
import IntegerDetails from '../../widgets/IntegerDetails'

const drinkTypeList = [
  { label: 'Glass of Wine (175 ml / 11% ABV)', id: 'wine' },
  { label: 'Pint of Beer (568 ml / 4% ABV)', id: 'beerPint' },
  { label: 'Bottle of Beer (330 ml / 5% ABV)', id: 'beerBottle' },
  { label: 'Alcoholic Pop Drink (275 ml / 4% ABV)', id: 'alcoholicPop' },
  { label: 'Shot of Spirits (25 ml / 40% ABV)', id: 'spiritShot' },
]

export const AlcoholType = ({ typeOfDrink, ...props }) => {
  return (
    <FormLabel label="Which of the following drinks do you most frequently consume?">
      <Select value={typeOfDrink} {...props}>
        {drinkTypeList.map(({ id, label }) => {
          return (
            <MenuItem key={id} value={id}>
              {label ?? id}
            </MenuItem>
          )
        })}
      </Select>
    </FormLabel>
  )
}

export const AlcoholDrinks = ({ ...props }) => {
  return (
    <FormLabel label={'On average, how many drinks do you have each week?'}>
      <IntegerDetails
        type="number"
        InputProps={{
          inputProps: {
            min: 0,
            max: 150,
          },
        }}
        {...props}
      />
    </FormLabel>
  )
}

const Alcohol = ({ id, patient, config }) => {
  const {
    questionnaire: { persons },
    localization,
  } = useSelector(({ questionnaire, localization }) => ({ questionnaire, localization }))
  const {
    setPersonConsumesAlcohol,
    removePersonCancerRiskProperty,
    setPersonTypeOfDrink,
    setPersonDrinkAmount,
    setPersonDrinkingFrequency,
  } = useActions()

  const { cancerRiskData: { consumesAlcohol = null, alcoholConsumptionDetails = [] } = {} } =
    persons[id]

  const {
    typeOfDrink = '',
    drinkAmount = '',
    drinkingFrequency = '',
  } = alcoholConsumptionDetails[0] || {}

  let alcoholConsumeSelected = null

  if (!consumesAlcohol) {
    if (consumesAlcohol === false) {
      alcoholConsumeSelected = 'N'
    }
  } else {
    alcoholConsumeSelected = 'Y'
  }

  return (
    <ConditionalSection
      label={getLabel(localization, paths.ALCOHOL_USAGE_LABEL, patient)}
      conditionState={alcoholConsumeSelected}
      onConditionStateChange={(value) => {
        if (value !== consumesAlcohol) {
          setPersonConsumesAlcohol({ id, value: value === 'Y' })
        }
      }}
      cleanUpChildQuestions={async () => {
        removePersonCancerRiskProperty({ id, type: 'alcoholConsumptionDetails' })
      }}
      showWhenCondition="Y"
      orderSchema={['Y', 'N']}
    >
      {renderContent({
        renderMap: {
          alcoholType: (
            <AlcoholType
              key="at"
              typeOfDrink={typeOfDrink}
              onChange={({ target: { value } }) => {
                if (value !== typeOfDrink) {
                  setPersonTypeOfDrink({ id, value })
                }
              }}
            />
          ),
        },
        config,
      })}
      {renderContent({
        renderMap: {
          alcoholAmount: (
            <AlcoholDrinks
              key="am"
              value={drinkAmount}
              onChange={(value) => {
                setPersonDrinkAmount({ id, value })
                if (!drinkingFrequency && value) {
                  setPersonDrinkingFrequency({ id, value: 'weekly' })
                }
              }}
              disabled={!typeOfDrink}
              disabledMinus={!typeOfDrink}
              disabledPlus={!typeOfDrink}
            />
          ),
        },
        config,
      })}
    </ConditionalSection>
  )
}

export default Alcohol
