import { getPerson } from '../../../slices/questionnaireSlice/utils/helpers'

/**
 * Set new property with type to person properties list
 * or update specific field in existing property
 * ({ type: 'hasBeenSeenByTeam', isPresent: 'Y', value: 'description' })
 * @type {(state, action, propertyField: string)}
 */
function setPersonPropertiesHelper(state, action, propertyField) {
  const { type, value } = action.payload
  const person = getPerson(state, action)

  if (!person.properties) {
    person.properties = []
  }
  const property = person.properties.find(({ type: _type }) => _type === type)

  // If property doesn't exist, create it
  if (!property) {
    person.properties.push({ type, [propertyField]: value })
  } else if (value === undefined || value === null) {
    delete property[propertyField]
  } else {
    property[propertyField] = value
  }
}

export default {
  /**
   * Set/add item to person property list with an isPresent value ({ type: 'twin', isPresent: 'Y' })
   * @type {(state: WritableDraft<{}>,
   *  action: { payload: { id: string, type: string, value: 'Y' | 'N' | undefined }}
   * )}
   */
  setPersonPropertiesIsPresent(state, action) {
    setPersonPropertiesHelper(state, action, 'isPresent')
  },
  /**
   * Set item to person properties list ({ type: 'sharedParent', value: 'Mother/Father' })
   * @type {(state: WritableDraft<{}>,
   *  action: { payload: { id: string, type: string, value: 'Mother' | 'Father' }}
   * )}
   */
  setPersonPropertiesValue(state, action) {
    setPersonPropertiesHelper(state, action, 'value')
  },
  /**
   * Remove item from person properties list ({ type: 'twin', isPresent: 'Y' })
   * @type {(state: WritableDraft<{}>, action: { payload: { id: string, type: string }})}
   */
  removePersonPropertiesList: (state, action) => {
    const { type: _type } = action.payload
    const person = getPerson(state, action)

    person.properties = person.properties.filter(({ type }) => _type !== type)
  },
}
