import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import ConditionalSection from '../../widgets/ConditionalSection'

const AshkenaziAncestry = ({ label }) => {
  const { setAshkenaziInheritance } = useActions()

  const { probandId, ashkenaziInheritance } = useSelector(
    ({ questionnaire: { probandId, persons } }) => ({
      probandId,
      ashkenaziInheritance: persons[probandId].cancerRiskData?.ashkenaziInheritance ?? null,
    }),
  )

  const setAshkenaziJewish = (value) => {
    if (value !== ashkenaziInheritanceState) {
      setAshkenaziInheritance({ id: probandId, value: value === 'Y' })
    }
  }

  const ashkenaziInheritanceState = (() => {
    switch (ashkenaziInheritance) {
      case true:
        return 'Y'
      case false:
        return 'N'
      default:
        return null
    }
  })()

  return (
    <ConditionalSection
      label={label}
      conditionState={ashkenaziInheritanceState}
      onConditionStateChange={(value) => setAshkenaziJewish(value)}
      orderSchema={['Y', 'N']}
      cleanUpChildQuestions={() => null}
    ></ConditionalSection>
  )
}

export default AshkenaziAncestry
