import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { getLabel, renderContent } from '../../utils/helpers'
import { PersonFieldPaths as paths } from '../../utils/QuestionnaireStateManager'
import Chooser from '../../widgets/Chooser'
import ConditionalSection from '../../widgets/ConditionalSection'

export const FITResult = ({ ...props }) => {
  return <Chooser orderSchema={['normal', 'abnormal', 'noResult']} {...props} />
}

const FIT = ({ id, patient, fitDiagnostic = {}, config }) => {
  const localization = useSelector(({ localization }) => localization)
  const { removePersonFITStatus, setPersonFITStatus } = useActions()

  return (
    <ConditionalSection
      showWhenCondition="Y"
      orderSchema={['Y', 'N', 'U']}
      key="FIT"
      label={getLabel(localization, paths.FIT_STATUS_LABEL, patient)}
      conditionState={fitDiagnostic.isPerformed}
      onConditionStateChange={(value) => {
        if (!fitDiagnostic.type) {
          setPersonFITStatus({ id, type: 'type', value: 'fit' })
        }

        if (fitDiagnostic.isPerformed !== value) {
          setPersonFITStatus({ id, type: 'isPerformed', value })
        }
      }}
      cleanUpChildQuestions={() => {
        removePersonFITStatus({ id, type: 'result' })
      }}
    >
      {renderContent({
        renderMap: {
          fitResult: (
            <FITResult
              key="fr"
              label={getLabel(localization, paths.DIAGNOSTIC_RESULT_LABEL, patient)}
              onChange={(value) => {
                if (fitDiagnostic.result !== value) {
                  setPersonFITStatus({ id, type: 'result', value })
                }
              }}
              selectedValue={fitDiagnostic.result}
              patient={patient}
              path={paths.DIAGNOSTIC_RESULT_LABEL}
            />
          ),
        },
        config,
      })}
    </ConditionalSection>
  )
}

export default FIT
