import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { getLabel } from '../../utils/helpers'
import { PersonFieldPaths } from '../../utils/QuestionnaireStateManager'
import Chooser from '../../widgets/Chooser'

const MantleRadiation = ({ id }) => {
  const {
    treatments = [],
    cancers = [],
    localization,
  } = useSelector(({ questionnaire: { persons }, localization }) => ({
    treatments: persons[id].treatments,
    cancers: persons[id].cancers,
    localization,
  }))
  const { setPersonTreatments } = useActions()
  const selectedValue = treatments[0]?.isPerformed
  const hodgkinLymphoma = cancers.find((cancer) => cancer.id === 'HP:0012189')

  return hodgkinLymphoma ? (
    <Chooser
      orderSchema={['Y', 'N', 'U']}
      path="yesNoUnknown"
      key="MantleRadiation"
      label={getLabel(localization, PersonFieldPaths.TREATMENT_MANTLE_RADIATION, 'person')}
      selectedValue={selectedValue}
      onChange={(value) => {
        if (value !== selectedValue) {
          setPersonTreatments({ id, type: 'mantleRadiation', value })
        }
      }}
    />
  ) : null
}

export default MantleRadiation
