import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import { getLabel } from '../../utils/helpers'
import { PersonFieldPaths as paths } from '../../utils/QuestionnaireStateManager'

import ConditionalSection from '../../widgets/ConditionalSection'

const Cancer = ({ id, patient, children }) => {
  const { persons, localization } = useSelector(({ questionnaire, localization }) => ({
    persons: questionnaire.persons,
    localization,
  }))
  const { setPersonPropertiesIsPresent, removePersonProperty } = useActions()
  const { cancers = [], properties = [] } = persons[id]
  const { isPresent = '' } = properties.find(({ type }) => type === 'hasCancer') || {}

  return (
    <ConditionalSection
      showWhenCondition="Y"
      orderSchema={['Y', 'N', 'U']}
      label={getLabel(localization, paths.DIAGNOSED_WITH_CANCER, patient)}
      conditionState={isPresent}
      onConditionStateChange={(value) => {
        if (value !== isPresent) {
          setPersonPropertiesIsPresent({ id, type: 'hasCancer', value })
        }
      }}
      cleanUpChildQuestions={() => {
        if (cancers.length > 0) {
          removePersonProperty({ id, type: 'cancers' })
        }
      }}
    >
      {children}
    </ConditionalSection>
  )
}

export default Cancer
