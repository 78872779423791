import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import DownloadIcon from '@mui/icons-material/Download'
import LogoutIcon from '@mui/icons-material/Logout'
import ReplayIcon from '@mui/icons-material/Replay'
import SaveIcon from '@mui/icons-material/Save'
import { LoadingButton } from '@mui/lab'
import { Box, Button, IconButton, useMediaQuery } from '@mui/material'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Auth } from '../api/Auth'
import { Download } from '../api/Download'
import { useActions } from '../hooks/useActions'
import { theme } from '../theme'
import Context from '../utils/context/Context'

function Controls({
  addToast,
  demoMode,
  handleMovePrevious,
  canMovePrevious,
  isLastStep,
  saveAndMoveToNext,
  setSubmitWarning,
  btnLabels,
  loadingControls,
}) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const history = useHistory()
  const { loading, setDialogBox } = useContext(Context)
  const { resetState, sendStateData } = useActions()

  // [Issue from the original code]: When keeping clicking `Try Again`,
  // it generates an out of bound error.
  // TODO: need to show DialogBox with different logic.
  // It should not have `Try Again` and `Without processing saving`
  const saveThen = async () => {
    const { payload: { errors = [] } = {}, error = '' } = (await sendStateData()) || {}

    if (errors.length || error) {
      setDialogBox({
        show: true,
        type: 'showSaveError',
        action: () => {},
        errorMessage: !errors.length ? error : errors,
      })

      return false
    } else {
      addToast('save', 'success', 'Save completed')

      return true
    }
  }

  return (
    <Box
      sx={{
        backdropFilter: 'blur(10px)',
        bgColor: 'white.transparent',
        boxShadow: 2,
        bottom: '0',
        display: 'flex',
        gap: 2,
        position: 'sticky',
        py: 3,
        px: 10,
        zIndex: '1',
        [theme.breakpoints.down('md')]: {
          position: 'fixed',
          py: 2,
          px: 2,
          width: '100%',
        },
      }}
    >
      {/* Save button */}
      {isMobile ? (
        <IconButton
          onClick={async () => {
            loadingControls('save', true)
            await saveThen()
            loadingControls('save', false)
          }}
          disabled={!!loading?.save}
          sx={{ width: 45, height: 45 }}
        >
          <SaveIcon />
        </IconButton>
      ) : (
        <LoadingButton
          startIcon={<SaveIcon />}
          onClick={async () => {
            loadingControls('save', true)
            await saveThen()
            loadingControls('save', false)
          }}
          loading={!!loading?.save}
          variant="outlined"
        >
          <span>Save</span>
        </LoadingButton>
      )}
      {/* Dev/Other Buttons */}
      {demoMode && !isMobile && (
        <>
          <Button
            color="error"
            startIcon={<ReplayIcon />}
            disabled={!!loading?.reset}
            onClick={async () => {
              loadingControls('reset', true)

              const { payload: { errors = [] } = {}, error = '' } = await resetState()

              // TODO: Need to delete `Try Again` button
              if (errors.length || error) {
                setDialogBox({
                  show: true,
                  type: 'showSaveError',
                  action: () => {},
                  errorMessage: !errors.length ? error : errors,
                })
              } else {
                addToast(
                  'reset',
                  'success',
                  'Form Responses Reset. You must manually remove patients from the ' +
                    'PhenoTips dashboard.',
                )
              }

              loadingControls('reset', false)
            }}
          >
            Reset
          </Button>
          {/* Why map uses only two buttons? */}
          {demoButtons.map((buttonInfo) => (
            <Button
              key={buttonInfo.id}
              color={buttonInfo.intent}
              startIcon={buttonInfo.icon}
              onClick={async () => {
                loadingControls(buttonInfo.id, true)

                const done = await saveThen()

                if (!done) {
                  loadingControls(buttonInfo.id, false)

                  return
                }

                if (buttonInfo.id === 'logout') {
                  await Auth.logout({ addToast })

                  history.push('/user-logout', {
                    message:
                      'You have logged out. Please login again to restart the questionnaire.',
                  })
                } else {
                  await Download.pdfDownload({ addToast })
                  loadingControls(buttonInfo.id, false)
                }
              }}
              disabled={loading ? !!loading[buttonInfo.id] : false}
            >
              {buttonInfo.text}
            </Button>
          ))}
        </>
      )}
      <Box flexGrow={1}></Box>
      <Button
        onClick={handleMovePrevious}
        disabled={!canMovePrevious() || !!loading?.save}
        startIcon={<ArrowBackIcon />}
      >
        {btnLabels[0]}
      </Button>
      {!isLastStep() ? (
        <LoadingButton
          variant="contained"
          onClick={async () => {
            await saveAndMoveToNext()
          }}
          loading={!!loading?.save}
          endIcon={<ArrowForwardIcon />}
        >
          <span>{btnLabels[1]}</span>
        </LoadingButton>
      ) : (
        <LoadingButton
          onClick={() => setSubmitWarning(true)}
          loading={!!loading?.save}
          endIcon={<ArrowForwardIcon />}
          variant="contained"
        >
          <span>{isMobile ? 'Submit' : 'Submit questionnaire'}</span>
        </LoadingButton>
      )}
    </Box>
  )
}

const demoButtons = [
  {
    icon: <DownloadIcon />,
    text: 'PDF',
    id: 'generate',
  },
  {
    icon: <LogoutIcon />,
    text: 'Logout',
    id: 'logout',
  },
]

export default Controls
