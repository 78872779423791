import { TextField } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../hooks/useActions'
import ConditionalSection from '../../widgets/ConditionalSection'
import FormLabel from '../../widgets/FormLabel'

const BreastFeeding = () => {
  const { setPersonPropertiesIsPresent, setPersonPropertiesValue } = useActions()
  const { probandId, persons } = useSelector(({ questionnaire }) => questionnaire)
  const { sex, properties } = persons[probandId]
  const hasBreastFed = properties.find(({ type }) => type === 'hasBreastFed') || {}

  const actionProps = (value) => ({ id: probandId, type: 'hasBreastFed', value })

  return sex === 'F' ? (
    <ConditionalSection
      showWhenCondition="Y"
      orderSchema={['Y', 'N']}
      label="Have you breast fed your children?"
      conditionState={hasBreastFed.isPresent}
      onConditionStateChange={(value) => {
        if (value !== hasBreastFed.isPresent) {
          setPersonPropertiesIsPresent(actionProps(value))
        }
      }}
      cleanUpChildQuestions={() => {
        if (hasBreastFed.value) {
          setPersonPropertiesValue(actionProps(null))
        }
      }}
    >
      <FormLabel label="For how long in total?">
        <TextField
          inputProps={{ maxLength: 300 }}
          onChange={({ target: { value } }) => {
            setPersonPropertiesValue(actionProps(value))
          }}
          value={hasBreastFed.value || ''}
        />
      </FormLabel>
    </ConditionalSection>
  ) : null
}

export default BreastFeeding
