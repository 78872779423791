import { Auth } from './Auth'
import { Main } from './Main'
import { navigateToErrorScreen } from './utils/errors'

export class Update extends Main {
  static baseUrl = this.getRoot()

  static async updateQuestionnaire({ questionnaire }) {
    const url = `${this.baseUrl}/questionnaire/current-session`

    try {
      const previousQuestionnaire = await Auth.getSession()

      previousQuestionnaire.pedigree = questionnaire
      previousQuestionnaire.notes = questionnaire.notes
      previousQuestionnaire.additionalHealthProblems = questionnaire.additionalHealthProblems
      previousQuestionnaire.relevantInfo = questionnaire.relevantInfo
      previousQuestionnaire.respondent = questionnaire.respondent
      previousQuestionnaire.isMetricMeasurementSystem = questionnaire.isMetricMeasurementSystem
      previousQuestionnaire.additionalRelativeInfos = questionnaire.additionalRelativeInfos
      previousQuestionnaire.consents = questionnaire.consents

      const updated = await (
        await fetch(url, {
          method: 'PUT',
          body: JSON.stringify(previousQuestionnaire),
          headers: {
            'Content-Type': 'application/json',
          },
        })
      ).json()

      // Error messages are in body from BE.
      if ((updated.pedigree || updated.errors || updated.error) && !updated.message) {
        return updated
      } else {
        const { status, message } = updated || {}

        throw new Error(status === 401 || status === 403 ? status : message || '*')
      }
    } catch (err) {
      navigateToErrorScreen({
        fetchType: 'updateQuestionnaire',
        status: err.message,
      })
    }
  }

  static async submitQuestionnaire() {
    const url = `${this.baseUrl}/questionnaire/current-session/submit`

    try {
      const submitted = await fetch(url, {
        method: 'POST',
      })

      if (!submitted.ok) {
        throw new Error()
      }

      return submitted.json()
    } catch (err) {
      navigateToErrorScreen({ fetchType: 'submission' })
    }
  }

  static async updateMetrics({ metricState }) {
    const url = `${this.baseUrl}/questionnaire-metrics`

    // Error control is not required.
    await fetch(url, {
      method: 'POST',
      body: JSON.stringify({ data: JSON.stringify(metricState) }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }
}
