import { createSlice } from '@reduxjs/toolkit'

export const localizationSlice = createSlice({
  name: 'localization',
  initialState: {},
  reducers: {
    setLocalizationValues(_, action) {
      return action.payload
    },
  },
})

const { reducer, actions } = localizationSlice
export const { setLocalizationValues } = actions

export default reducer
